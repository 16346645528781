import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [sessionFor2FA, setSessionFor2FA] = useState(null);

  const updateUser = (updatedUser) => {
    setUser((prevUser) => ({
      ...prevUser,
      data: {
        ...prevUser.data,
        user: {
          ...prevUser.data.user,
          ...updatedUser,
        },
      },
    }));
  };
   const updateThirdPartyInfo = (newInfo) => {
     setUser((prevUser) => ({
       ...prevUser,
       data: {
         ...prevUser.data,
         thirdPartyInfo: newInfo,
       },
     }));
   };
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem("authToken", userData.data.token);
    localStorage.setItem("user", JSON.stringify(userData));
    window.location.href = "/dashboard/";
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider value={{ user, updateUser, login, logout, setSessionFor2FA, sessionFor2FA, updateThirdPartyInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
