import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Divider, Typography } from "antd";

import { sidebarTabs } from "../../utils/sideBarMenu";
import OrbitXLogo from "../../assets/orbitx-logo-white.png";

const { Text } = Typography;

const Sidebar = () => {
  const location = useLocation(); // Get the current route location

  return (
    <div className="bg-appBlue w-64 h-screen">
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col gap-10">
          <div className="px-4 py-2 h-12 bg-appNavyBlue">
            <img src={OrbitXLogo} alt="OrbitX Logo" className="h-8" />
          </div>
          <ul>
            {sidebarTabs.map((tab) => (
              <li key={tab.link} className="flex justify-center">
                <Link
                  to={tab.link}
                  className={`p-4 w-full flex items-center gap-3 ${
                    location.pathname === tab.link
                      ? "bg-white text-appBlue transition ease-in duration-200"
                      : "text-white hover:bg-blue-400 hover:transition hover:ease-in-out hover:duration-200"
                  }`}
                >
                  <img
                    className="h-4"
                    src={
                      location.pathname === tab.link
                        ? tab.iconBlue
                        : tab.iconWhite
                    }
                  />
                  {tab.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="pb-14">
          <Divider className="bg-white mb-2" />
          <Text className="text-white pl-5">V1.0</Text>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
