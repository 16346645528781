import React, { useContext, useState } from "react";
import { Typography, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";

import Background from "../components/Background";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import api from "../api/axios";
import { notificationsContext } from "../context/Notifications";

const { Title, Text, Link } = Typography;

const SetUpPassword = ({temporaryUserData, setIsNewPasswordRequired}) => {
  const { Item } = Form;
  const navigate = useNavigate();
  const { api: notificationAPI } = useContext(notificationsContext);
  const [temporaryPassword, setTemporaryPassword] = useState(""); 
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsNewPasswordRequired(false)
    navigate('/signin');
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const formattedValues = {
        email: temporaryUserData?.email,
        temp_password: temporaryPassword,
        new_password: values.password,
      };
      await api.post("/set-new-password", formattedValues);
      setIsLoading(false);
      setIsNewPasswordRequired(false);
      navigate('/signin')
         notificationAPI.success({
           message: "New password is set successfully",
           placement: "top",
           duration: 4,
         });
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };

  const validatePassword = (value) => {
    const errors = [];
    if (value.length < 8) errors.push("at least 8 characters long");
    if (!/[A-Z]/.test(value)) errors.push("1 uppercase letter");
    if (!/[a-z]/.test(value)) errors.push("1 lowercase letter");
    if (!/[0-9]/.test(value)) errors.push("1 number");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
      errors.push("1 special character");
    // Check if new password matches temporary password
    if (value === temporaryPassword) {
      errors.push("not match the temporary password");
    }
    if (errors.length > 0) {
      setPasswordError(`Your password must be ${errors.join(", ")}.`);
    } else {
      setPasswordError("");
    }
  };
   const customizeRequiredMark = (label, { required }) => (
     <>
       {label}
       {required && <span style={{ color: "red" }}>*</span>}
     </>
   );
  return (
    <>
      <CustomCard>
        <Title level={2} className="!font-bold">
          Set New Password
        </Title>

        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          requiredMark={customizeRequiredMark}
        >
          <Item
            label="Temporary Password"
            name="temporary_password"
            rules={[
              {
                required: true,
                message: "Please enter your temporary password",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your temporary password"
              size="large"
              onChange={(e) => setTemporaryPassword(e.target.value)}
            />
          </Item>

          <Item
            label="Password"
            name="password"
            rules={[
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Please enter your password")
                    );
                  }
                  validatePassword(value);
                  if (passwordError) {
                    return Promise.reject(new Error(passwordError));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              size="large"
              onChange={(e) => validatePassword(e.target.value)}
            />
          </Item>

          <Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm your password" size="large" />
          </Item>

          <Item>
            <CustomButton
              text={isLoading ? <Spin /> : "Set Password"}
              type="primary"
              htmlType="submit"
              disabled={isLoading}
            />
          </Item>
        </Form>
        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </CustomCard>
    </>
  );
};

export default SetUpPassword;
