import React, { useContext } from "react";
import { BellOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Dropdown, Typography } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import api from "../../api/axios";

const { Text } = Typography;

const Header = () => {
  const navigate = useNavigate();
  const { logout, user } = useContext(AuthContext);

  const items = [
    {
      key: "1",
      label: (
        <Text
          onClick={async () => {
            // api.post("/logout", )
            logout();
            navigate("/signin");
          }}
        >
          Logout
        </Text>
      ),
    },
  ];

  return (
    <div className="px-4 py-2 h-12 bg-appNavyBlue flex justify-end items-center gap-5">
      <Badge count={0} size="small" className="cursor-pointer">
        <BellOutlined className="text-white text-2xl" />
      </Badge>

      <Avatar icon={<UserOutlined />} className="bg-gray-300" />

      <Text className="text-white">{user?.data?.user?.name}</Text>

      <Dropdown
        menu={{
          items,
        }}
      >
        <DownOutlined className="text-white" />
      </Dropdown>
    </div>
  );
};

export default Header;
