import React, { useState, useContext, useEffect } from "react";
import {
  Spin,
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Button,
} from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import {
  ArrowLeftOutlined,
  LinkOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import CustomButton from "../components/CustomButton";
import assessmentApi from "../api/assessmentAxios";
import { AuthContext } from "../context/AuthContext";
import { AssessmentStatus } from "../utils/constants";
import { UploadOutlined } from "@ant-design/icons";
import api from "../api/axios";
import newAsset from "../api/assetAxios";
import nis2 from "../assets/NIS2.jpeg";
import iec from "../assets/IEC.png";

const ScheduleAssessment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [allStandards, setAllStandards] = useState([]);
  const [allIndustries, setAllIndustries] = useState([]);
  const [files, setFiles] = useState([]);
  const [assignedToEmail, setAssignedToEmail] = useState("");
  const [primaryContactEmail, setPrimaryContactEmail] = useState("");
  const [selectedType, setSelectedType] = useState({
    name: "",
    standard_id: "",
  });
  const [selectedIndus, setSelectedIndus] = useState();
  const [selectedAsset, setSelectedAsset] = useState({
    nozomi_id: "",
    asset_name: "",
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [userList, setUserList] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Item } = Form;
  const { TextArea } = Input;
  console.log(files);
  useEffect(() => {
    (async () => await getStandards())();
  }, []);

  const getStandards = async () => {
    try {
      const standards = await assessmentApi.get("/standard");
      const industries = await assessmentApi.get("/assessment/get-industries");
      const res = await newAsset.get(`/asset`);
      const users = await api.get("/user/list-user");
      setAssetData(res?.data?.data?.rows);
      setAllIndustries(industries?.data?.data);
      setAllStandards(standards?.data?.data?.rows);
      setUserList(users?.data?.data);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsTokenExpiredModalOpen(true);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const handleCancel = () => {
    form.resetFields();
    navigate(`/dashboard/assessment`);
  };
  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const newDate = new Date(values.due_date);
      const formData = {
        ...values,
        name: values.name,
        due_date: newDate.toISOString(),
        status: AssessmentStatus.NOT_YET_STARTED,
        type: selectedType?.name,
        standard_id: selectedType?.standard_id,
        primary_contact_email: primaryContactEmail,
        assigned_to_email: assignedToEmail,
        tenant_id: user?.data?.user?.tenant_id,
        industry: selectedIndus,
        assets: [
          {
            nozomi_id: selectedAsset.nozomi_id,
            asset_name: selectedAsset.asset_name,
          },
        ],
        description: values.textArea,
        location: values.location
      };
      await assessmentApi.post("/assessment", formData);
      form.resetFields();
      setIsLoading(false);
      navigate(`/dashboard/assessment`);
    } catch (err) {
      setIsLoading(false);
      if (err?.response?.data?.error?.includes("Assessment already exists")) {
        form.setFields([
          {
            name: "name",
            errors: ["Assessment already exists with this name"],
          },
        ]);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of the current day
    return current && current.valueOf() < today.getTime(); // Disable dates before today
  };
  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  // const handleFileChange = (event) => {
  //   const selectedFiles = event?.target?.files;
  //   if (selectedFiles && selectedFiles?.length > 0) {
  //     const newFiles = Array.from(selectedFiles);
  //      newFiles.forEach((file) => {
  //        if (file.size <= 50 * 1024 * 1024) {
  //          setFiles((prevFiles) => [...prevFiles, file]);
  //        } else {
  //          notificationAPI.error({
  //            message: `Error: ${file.name} exceeds the 50MB limit`,
  //            description: "Please select a file that is 50MB or smaller",
  //            placement: "top",
  //            duration: 4,
  //          });

  //        }
  //      });
  //   }
  // };
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const droppedFiles = event.dataTransfer.files;
  //   if (droppedFiles.length > 0) {
  //     const newFiles = Array.from(droppedFiles);
  //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //   }
  // };

  // const handleRemoveFile = (index) => {
  //   setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  // };
  // useEffect(() => {
  //   setFiles(files);
  // }, [files]);
  const imageMapping = {
    NIS2: nis2,
    "IEC-62443-2-1": iec,
  };
  const handleFunction = (standard, index) => {
    setSelectedType({ name: standard.name, standard_id: standard.id });
    setSelectedItem(index);
  };

  return (
    <DashboardBackground bg={"bg"}>
      <div className="h-16 w-full bg-white px-5 flex justify-between items-center gap-4">
        <div className="flex flex-row gap-4">
          <ArrowLeftOutlined
            onClick={() => navigate(`/dashboard/assessment`)}
          />
          <p style={{ fontSize: "18px", fontWeight: "600" }}>
            Schedule New Assessment
          </p>
        </div>
        <div>
          <CustomButton
            text={"Create Custom Assessment"}
            type="primary"
            className={"h-11"}
          />
        </div>
      </div>
      <div className="h-[80vh] m-3 bg-white px-2 overflow-scroll overflow-x-hidden">
        <Form
          className="mt-5"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={customizeRequiredMark}
        >
          <style>
            {` 
            .custom-spin .ant-spin-dot {
              color: white;
            }
              .image-gallery {
               display: grid; 
               grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
              //  gap: 7px; 
               cursor: pointer;
             }
              .img-div{
             width: 200px
             }
              .img-div:hover{
              border: 1px solid #6495ED;
              }
            .image {
             width: 200px; 
             height: 100px; 
             object-fit: cover;
            }
            `}
          </style>
          <div className="bg-slate-100 p-5 w-full">
            <Item
              label={
                <div>
                  <span>Assessment Type</span>
                  {selectedType.name ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : null}
                </div>
              }
              className="font-roboto"
              name="standard_id"
              rules={[
                {
                  required: selectedType.name ? false : true,
                  message: "Please select assessment type",
                },
              ]}
            >
              <div className="image-gallery flex w-full flex-row gap-10 justify-start p-2">
                {allStandards.map((standard, index) => (
                  <div
                    key={standard?.id}
                    style={{
                      cursor: "pointer",
                      border:
                        selectedItem === index ? "1px solid #1890FF" : null,
                    }}
                    className="flex flex-col gap-3 justify-center items-center w-32 border hover:border-blue-500 focus:border-blue-500"
                  >
                    <div className="image-container">
                      <img
                        src={imageMapping[standard?.standard_code]}
                        alt={standard?.name}
                        className="image"
                        onClick={() => handleFunction(standard, index)}
                      />
                    </div>
                    <div>
                      <p
                        style={{
                          color: selectedItem === index ? "#1890FF" : null,
                        }}
                      >
                        {standard?.standard_code}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </Item>

            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Assessment Name"
                  className="font-roboto"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter assessment name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Assessment Name"
                    classNames="font-normal"
                    size="large"
                    className="font-normal"
                  />
                </Item>
              </div>
              <div className="w-full md:w-1/2">
                <Item
                  label="Assigned To"
                  className="font-roboto"
                  name="assigned_to"
                  rules={[{ required: true, message: "Please select a user" }]}
                >
                  <Select
                    placeholder="Select a person"
                    size="large"
                    onChange={(value) => {
                      const selectedUser = userList.find(
                        (user) => user.name === value
                      );
                      if (selectedUser) {
                        setAssignedToEmail(selectedUser.email);
                      }
                    }}
                  >
                    {userList.map((user) => (
                      <Option key={user.id} value={user.name}>
                        {user.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Industry"
                  className="font-roboto"
                  name="industry"
                  rules={[
                    {
                      required: true,
                      message: "Please select industry",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Industry"
                    size="large"
                    onChange={(value) => {
                      const selectedIndus = allIndustries.find(
                        (indus) => indus.name === value
                      );
                      if (selectedIndus) {
                        setSelectedIndus(selectedIndus.name);
                      }
                    }}
                  >
                    {allIndustries.map((indus) => (
                      <Option key={indus.id} value={indus.name}>
                        {indus.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </div>
              <div className="w-full md:w-1/2">
                <Item
                  label="Assets In Scope"
                  className="font-roboto"
                  // name="assetName"
                  rules={[
                    { required: true, message: "Please select a Asset name" },
                  ]}
                >
                  <Select
                    placeholder="Select asset"
                    size="large"
                    onChange={(value) => {
                      const selectedAsset = assetData.find(
                        (asset) => asset.name === value
                      );
                      if (selectedAsset) {
                        setSelectedAsset({
                          nozomi_id: selectedAsset.nozomi_asset_id,
                          asset_name: selectedAsset.name,
                        });
                      }
                    }}
                  >
                    {assetData.map((asset) => (
                      <Option key={asset.id} value={asset.name}>
                        {asset.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full">
                <Item
                  label="Description"
                  className="font-roboto"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please write description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Description"
                    classNames="font-normal"
                    size="large"
                    name="description"
                    style={{ resize: "none" }}
                    rows={6}
                  />
                </Item>
              </div>
            </div>

            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Primary Contact"
                  className="font-roboto"
                  name="primary_contact"
                  rules={[
                    { required: true, message: "Please select a contact" },
                  ]}
                >
                  <Select
                    placeholder="Select a contact"
                    size="large"
                    onChange={(value) => {
                      const selectedUser = userList.find(
                        (user) => user.name === value
                      );
                      if (selectedUser) {
                        setPrimaryContactEmail(selectedUser.email);
                      }
                    }}
                  >
                    {userList.map((user) => (
                      <Option key={user.id} value={user.name}>
                        {user.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </div>

              <div className="w-full md:w-1/2">
                <Item
                  label="Due Date (MM/DD/YYYY)"
                  className="font-roboto"
                  name="due_date"
                  rules={[
                    { required: true, message: "Please enter a valid date" },
                  ]}
                >
                  <DatePicker
                    size="large"
                    style={{ width: "100%" }}
                    disabledDate={disabledDate}
                    format="MM/DD/YYYY"
                    className="font-normal"
                  />
                </Item>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Location"
                  className="font-roboto"
                  name="location"
                  rules={[{ required: true, message: "Please add a location" }]}
                >
                  <Input
                    placeholder="Location"
                    classNames="font-normal"
                    name="location"
                    size="large"
                  />
                </Item>
              </div>
              <div className="w-full md:w-1/2">
                <Item
                  label="Supporting Document"
                  className="font-roboto"
                  // name="info"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please upload supporting information",
                  //   },
                  // ]}
                >
                  <Button
                    className="h-11 w-1/2 text-sky-500"
                    // type="primary"
                    onClick={() => document.getElementById("browse").click()}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="browse"
                      // onChange={handleFileChange}
                      accept=".pdf,.docs"
                      multiple={false}
                      max-size={50 * 1024 * 1024}
                    />
                    <UploadOutlined style={{ marginRight: 3 }} />
                    <span>Upload</span>
                  </Button>
                </Item>
              </div>
            </div>
            {files?.length > 0 && (
              <Item className="font-roboto">
                {files.map((file, index) => (
                  <div
                    className="w-44 rounded-md h-12 flex  p-2 items-center justify-between"
                    style={{
                      border: "1px solid rgba(145, 213, 255, 1)",
                      backgroundColor: "rgba(230,247,255,1)",
                      color: "#1890FF",
                    }}
                    key={index}
                  >
                    <LinkOutlined
                      className="text-sm cursor-pointer"
                      style={{ color: "#1890FF" }}
                    />
                    <div className="file-info w-28">
                      <p className="font-roboto truncate">{file.name}</p>
                    </div>
                    <CloseCircleOutlined
                      onClick={() => handleRemoveFile(index)}
                      className="text-[18px] cursor-pointer"
                      style={{ color: "#1890FF" }}
                    />
                  </div>
                ))}
              </Item>
            )}
          </div>
          <div className="flex flex-row gap-4 justify-end mt-5">
            <Item>
              <CustomButton
                text={isLoading ? <Spin className="custom-spin" /> : "Schedule"}
                type="primary"
                htmlType="submit"
                className="h-11 w-28"
              />
            </Item>
            <Item>
              <CustomButton
                text="Cancle"
                variant="outlined"
                className="h-11 mb-3"
                onClick={handleCancel}
              />
            </Item>
          </div>
        </Form>
        {/* </div> */}
      </div>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default ScheduleAssessment;
