import React from "react";
import { Typography, Form, Input } from "antd";
import InputMask from "react-input-mask";

import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";

const { Title, Text } = Typography;

const SignInVerify = ({ handleNextStep }) => {
  const { Item } = Form;

  const validatePhoneNumber = (_, value) => {
    const numericPhone = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericPhone.length >= 10 && numericPhone.length <= 14) {
      return Promise.resolve(); // Pass validation for European numbers
    }
    return Promise.reject(
      new Error("Please enter a valid phone number in European format")
    );
  };

  const onFinish = (values) => {
    const formattedValues = {
      ...values,
      sms: values.sms.replace(/\D/g, ""), // Remove all non-numeric characters
    };
  };

  return (
    <CustomCard>
      <Title level={2} className="!font-bold">
        Verify Your Identity
      </Title>

      <Text type="secondary">
        Verify your Identity with phone number or email.
      </Text>

      <Form onFinish={onFinish} layout="vertical" className="mt-5">
        <Item
          label="SMS"
          name="sms"
          rules={[
            { required: true, message: "Please enter your phone number" },
            { validator: validatePhoneNumber },
          ]}
        >
          <InputMask mask="+99 9999 999 999" placeholder="+44 1234 567 890">
            {(inputProps) => <Input {...inputProps} size="large" />}
          </InputMask>
        </Item>

        <Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              message: "The input is not a valid email!",
            },
          ]}
        >
          <Input placeholder="Enter your email for verification" size="large" />
        </Item>

        <Item>
          <CustomButton
            text="Verify"
            type="primary"
            htmlType="submit"
            onClick={handleNextStep}
          />
        </Item>
      </Form>
    </CustomCard>
  );
};

export default SignInVerify;
