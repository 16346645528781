import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Divider, Typography } from "antd";
import React from "react";

const { Title, Text } = Typography;

const AssessmentCard = ({ bgColor, title, value }) => {
  return (
    <Card bordered={false} className={`w-full border ${bgColor}`}>
      <div className="flex justify-between">
        <Text type="secondary">{title}</Text>
        <InfoCircleOutlined className="text-[#A2B97E]" />
      </div>
      <Title level={2}>{value}</Title>
      <Divider />
    </Card>
  );
};

export default AssessmentCard;
