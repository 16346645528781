export const AssessmentDetailBreadCrumbList = [
  {
    title: "Dashboard",
  },
  {
    title: "Assessment",
  },
  {
    title: "Assessment Detail",
  },
];
export const RemediationDetailBreadCrumbList = [
  {
    title: "Dashboard",
  },
  {
    title: "Remediation",
  },
  {
    title: "Requirement",
  },
];

export const MAX_FILE_SIZE = 5;
export const THIRD_PARTY_NAME = "NOZOMI NETWORKS";

export const AssessmentStatus = {
  NOT_YET_STARTED: "Not Yet Started",
  IN_PROGRESS: "In Progress",
  REVIEWING: "Reviewing",
  COMPLETED: "Completed",
};

export const QuestionStatus = [
  {
    value: "In Place",
    label: "In Place",
  },
  {
    value: "Partially In Place",
    label: "Partially In Place",
  },
  {
    value: "Not In Place",
    label: "Not In Place",
  },
  {
    value: "Not Applicable",
    label: "Not Applicable",
  },
];
