export const assessmentCardList = [
  {
    bgColor: "bg-[#FFF7ED]",
    status: "Not Yet Started",
    count: 0,
  },
  {
    bgColor: "bg-[#FFF1F2]",
    status: "In Progress",
    count: 0,
  },
  {
    bgColor: "bg-[#EEF2FF]",
    status: "Reviewing",
    count: 0,
  },
  {
    bgColor: "bg-[#F0FDFA]",
    status: "Completed",
    count: 0,
  },
];
