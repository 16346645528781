import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider } from "antd";

import AppRoutes from "./routes/AppRoutes";
import { AuthProvider } from "./context/AuthContext";
import { useContext } from "react";
import { notificationsContext } from "./context/Notifications";

function App() {
  const { contextHolder } = useContext(notificationsContext);

  return (
    <>
      {contextHolder}
      <AuthProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Inter",
            },
          }}
        >
          <Router>
            <AppRoutes />
          </Router>
        </ConfigProvider>
      </AuthProvider>
    </>
  );
}

export default App;
