import React, { useState } from "react";
import { Typography } from "antd";
import CustomButton from "../CustomButton";
import Questions from "./Questions";

const { Title } = Typography;

const QuestionsWrapper = ({
  name,
  data,
  setUpdatedAnswers,
  handleSaveQuestionnaire,
  assessmentId,
  isSaving,
  stepsCount,
  currentStep,
  setProgressValue,
  valueToAddInProgress,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  answers,
  setAnswers,
  fileLists,
  setFileLists,
  questionStatus,
  setQuestionStatus,
}) => {
  // Function to handle moving to the next question
  const handleNext = () => {
    if (currentQuestionIndex < data?.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setProgressValue((prev) => +(+prev + +valueToAddInProgress).toFixed(2));
    }
  };

  // Function to handle moving to the previous question
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      setProgressValue((prev) => +(+prev - +valueToAddInProgress).toFixed(2));
    }
  };

  return (
    <>
      <div className="flex flex-col h-auto w-full px-3 mt-10">
        {/* Display only the current question */}
        {data?.length > 0 && (
          <Questions
            question={data[currentQuestionIndex]}
            setUpdatedAnswers={setUpdatedAnswers}
            assessmentId={assessmentId}
            answers={answers}
            setAnswers={setAnswers}
            fileLists={fileLists}
            setFileLists={setFileLists}
            questionStatus={questionStatus}
            setQuestionStatus={setQuestionStatus}
          />
        )}

        <div className="w-full flex justify-between mt-12">
          <div className="w-36">
            <CustomButton
              text="Previous Question"
              type="primary"
              className="h-9 rounded-md bg-appLightBlue"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0} // Disable if on the first question
            />
          </div>
          <div className="w-36">
            <CustomButton
              text="Next Question"
              type="primary"
              className="h-9 rounded-md bg-appLightBlue"
              onClick={handleNext}
              disabled={currentQuestionIndex === data?.length - 1} // Disable if on the last question
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsWrapper;
