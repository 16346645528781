import React, { useState, useContext, useEffect } from "react";
import { Spin, Card, Form, Input, Select } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomButton from "../components/CustomButton";
import { encryptDataWithIV } from "../utils/encryptionData";
import { AuthContext } from "../context/AuthContext";

import api from "../api/axios";

const NozomiConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const { user, updateThirdPartyInfo } = useContext(AuthContext);
  const [configValue, setConfigValue] = useState({
    username: "",
    password: "",
  });
  const [usernameDisabled, setUsernameDisabled] = useState(false);
  const [passwordDisabled, setPasswordDisabled] = useState(false);
  const [apiKeyDisable, setApiKeyDisable] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Item } = Form;
  const handleApiKeyChange = (e) => {
    const value = e.target.value;
    setApiKey(value);
    if (value) {
      setUsernameDisabled(true);
      setPasswordDisabled(true);
      form.setFieldsValue({ userName: "", password: "" });
    } else {
      setUsernameDisabled(false);
      setPasswordDisabled(false);
    }
  };
  const handleDisabled = (e) => {
    const { username, password } = form.getFieldsValue();
    setConfigValue({ username: username, password: password });

    if (username && password) {
      setApiKeyDisable(true);
    } else {
      setApiKeyDisable(false);
    }
  };

  const onFinish = async (values) => {
    const encryptedData = encryptDataWithIV(
      values,
      process.env.REACT_APP_3RDPC_ENC_KEY
    );
    const data = {
      tenant_id: user?.data?.user?.tenant_id,
      cred_info: {
        iv: encryptedData?.iv,
        ciphertext: encryptedData?.ciphertext,
      },
      third_party_name: "NOZOMI NETWORKS",
    };
    try {
      setIsLoading(true);
        const res = await api.post("/user/add-third-party", data);
         form.resetFields();
        setApiKeyDisable(false)
        setPasswordDisabled(false)
        setUsernameDisabled(false)
      setIsLoading(false);
        const newConnection = { third_party_name: "NOZOMI NETWORKS" };
      const updatedInfo = [...user.data.thirdPartyInfo, newConnection];
      updateThirdPartyInfo(updatedInfo)
      navigate(`/dashboard/integration`)
        notificationAPI.success({
           message: "NOZOMI NETWORK is configured successfully",
           placement: "top",
           duration: 4,
         });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-start items-center gap-4">
        <ArrowLeftOutlined onClick={() => navigate(`/dashboard/integration`)} />
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          Configure Nozomi Networks
        </p>
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5">
        <div className="mt-6">
          <Form
            className="mt-5"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <style>
              {` .custom-spin .ant-spin-dot {
              color: white;
            }`}
            </style>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="API URL"
                  className="font-roboto font-bold"
                  name="apiUrl"
                  rules={[
                    {
                      required: true,
                      message: "Please enter API URL",
                    },
                    {
                      type: "url",
                      message: "Please enter a valid URL",
                    },
                  ]}
                >
                  <Input
                    placeholder="API URL"
                    classNames="font-normal"
                    size="large"
                    className="font-normal"
                  />
                </Item>
              </div>
              <div className="w-full md:w-1/2">
                <Item
                  label="User Name"
                  className="font-roboto font-bold"
                  name="username"
                  rules={[
                    {
                      required: !apiKey ? true : false,
                      message: "Please enter user name",
                    },
                  ]}
                >
                  <Input
                    placeholder="User Name"
                    classNames="font-normal"
                    size="large"
                    className="font-normal"
                    disabled={usernameDisabled}
                    onChange={handleDisabled}
                  />
                </Item>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-4 justify-between">
              <div className="w-full md:w-1/2">
                <Item
                  label="Password"
                  className="font-roboto font-bold"
                  name="password"
                  rules={[
                    {
                      required: !apiKey,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input
                    placeholder="Password"
                    classNames="font-normal"
                    size="large"
                    className="font-normal"
                    disabled={passwordDisabled}
                    onChange={handleDisabled}
                  />
                </Item>
              </div>
              <div className="w-full md:w-1/2">
                <Item
                  label="API Key"
                  className="font-roboto font-bold"
                  name="apiKey"
                  rules={[
                    {
                      required: !configValue.username || !configValue.password,
                      message: "Please enter api key",
                    },
                    {
                      pattern: /^[A-Za-z0-9-_]+$/,
                      message:
                        "API Key can only contain letters, numbers, dashes, and underscores",
                    },
                  ]}
                >
                  <Input
                    placeholder="API Key"
                    classNames="font-normal"
                    type="text"
                    size="large"
                    className="font-normal"
                    disabled={apiKeyDisable}
                    value={apiKey}
                    onChange={handleApiKeyChange}
                  />
                </Item>
              </div>
            </div>

            <Item className="flex justify-end">
              <CustomButton
                text={
                  isLoading ? <Spin className="custom-spin" /> : "Configure"
                }
                type="primary"
                htmlType="submit"
                className="h-11 mt-4 w-28"
              />
            </Item>
          </Form>
        </div>
      </div>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default NozomiConfig;
