import axios from "axios";

const aiApi = axios.create({
  baseURL: process.env.REACT_APP_AI_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "X-API-Key": process.env.REACT_APP_AI_API_KEY,
  },
});

export default aiApi;
