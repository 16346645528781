import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Dashboard from "../components/Dashboard/Dashboard";
import Welcome from "../pages/Welcome";
import ForgotPassword from "../pages/ForgotPassword";
import TestNozomi from "../pages/TestNozomi";
import ResetPassword from "../pages/ResetPassword";

const isAuthenticated = () => {
  return !!localStorage.getItem("authToken");
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/testnozomi" element={<TestNozomi />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:email" element={<ResetPassword/>}/>
      <Route
        path="/dashboard/*"
        element={isAuthenticated() ? <Dashboard /> : <Navigate to="/signin" />}
      />
      <Route
        path="*"
        element={isAuthenticated() ? <Navigate to="/dashboard/" /> : <Navigate to="/welcome" />}
      />
    </Routes>
  );
};

export default AppRoutes;
