import React from "react";
import { Typography } from "antd";
import CustomButton from "../CustomButton";
import { ApiOutlined } from "@ant-design/icons";
import { ExportOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Introduction = ({ introduction }) => {
  return (
    <>
      <div className="flex justify-between">
        <Title level={2}>Introduction</Title>
      </div>

      <div className="flex flex-col gap-8 h-[200px] min-[1060px]:h-[250px] min-[1535px]:h-auto px-3 overflow-y-auto">
        <div>
          <Title level={4}>About Standards</Title>
          <Text>{introduction?.summary}</Text>
        </div>

        <div>
          <Title level={4}>Key Points</Title>
          <ul className="list-disc ml-5">
            {/* {introduction?.key_points?.map((point) => (
              <li>
                <Text>{point}</Text>
              </li>
            ))} */}
            {introduction?.key_points
              ?.reduce((acc, point, index) => {
                // Check if the index is even to start a new row
                if (index % 2 === 0) {
                  acc.push([point]); // Start a new pair
                } else {
                  acc[acc.length - 1].push(point); // Add to the last pair
                }
                return acc;
              }, [])
              .map((pair, index) => (
                <li key={index} className="flex space-x-4">
                  {pair.map((point, pointIndex) => (
                    <li style={{ width: "50%" }}>
                      <Text key={pointIndex}>{point}</Text>
                    </li>
                  ))}
                </li>
              ))}
          </ul>
        </div>

        <div>
          <Title level={4}>Quick Links</Title>
          <div className="flex flex-col">
            {introduction?.quick_links?.map((link, index) => (
              <div
                className="flex flex-row gap-3 py-4 px-9  w-fit cursor-pointer"
                onClick={() =>
                  window.open(link, "_blank", "noopener,noreferrer")
                }
              >
                {/* <ApiOutlined className="text-4xl text-appLightBlue" /> */}
                <Text>Link {index + 1}</Text>
                <ExportOutlined className="text-1xl text-appLightBlue" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
