import React from "react";
import { Typography } from "antd";

import logoBlue from "../assets/orbitx-logo-blue.png";
import Background from "../components/Background";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <Background>
      <CustomCard>
        <img src={logoBlue} />
        <div className="w-96 text-left mt-3">
          <Text type="secondary" className="text-xl">
            Excellence in compliance and risk management.
          </Text>
        </div>
        <div className="text-left mt-10">
          <Text className="text-3xl">Welcome to OrbitX</Text>
        </div>
        <div className="flex flex-col gap-2 mt-6">
          <CustomButton
            text="Get Started"
            type="primary"
            onClick={() => navigate("/signup")}
          />
          <CustomButton
            text="Sign In"
            variant="outlined"
            onClick={() => navigate("/signin")}
          />
        </div>
      </CustomCard>
    </Background>
  );
};

export default Welcome;
