import React, { useEffect, useState } from "react";
import { Avatar, Breadcrumb, Card, Progress, Spin, Tabs, Tooltip, Typography,Tag, Badge, Input } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import {
  RemediationDetailBreadCrumbList,
} from "../utils/constants";
import { ArrowLeftOutlined, UserOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";


const RemediationDetail = () => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex flex-col  justify-center items-start  gap-2">
        <div>
          <Breadcrumb items={RemediationDetailBreadCrumbList} />
        </div>
        <div className="flex flex-row gap-3">
          <ArrowLeftOutlined
            onClick={() => navigate(`/dashboard/remediation`)}
          />
          <p style={{ fontSize: "18px", fontWeight: "600" }}>
            Requirement Details
          </p>
        </div>
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5">
        <Card className="bg-slate-100">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row justify-start items-center gap-5">
              <div className="text-black">
                Why is NIS2 applicable to entity?
              </div>
              <div className="flex flex-row justify-start items-center gap-3">
                {/* <div>
                  <Avatar icon={<UserOutlined />} />
                </div> */}
                <div className="text-black">User</div>
                <div>
                  <Tag color="error">High</Tag>
                </div>
              </div>
            </div>
            <div>
              <Badge status="success" text="Resolve" />
            </div>
          </div>
          <div className="flex flex-row justify-start items-center gap-5 mt-3">
            <div className="flex flex-row justify-start items-center gap-3">
              <div className="text-stone-500">Assigned Date:</div>
              <div>01/01/2025</div>
            </div>
            <div className="flex flex-row justify-start items-center gap-3">
              <div className="text-stone-500">Due Date:</div>
              <div>01/01/2025</div>
            </div>
          </div>
          <div className="mt-5">
            <TextArea
              value={
                "Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur."
              }
              disabled
              style={{
                backgroundColor: "white",
                color: "black",
                padding: "10px",
                resize: "none",
              }}
            />
          </div>
          <div className="mt-5 text-black text-base">Evidence</div>
          <div className="flex flex-row justify-start items-center gap-3 mt-3 text-sky-500">
            <div>Doc_Cyber.pdf</div>
            <div>
              <DownloadOutlined />
            </div>
          </div>
          <div className="flex flex-row justify-start items-center gap-3 mt-2 text-sky-500">
            <div>Test_Files.docs</div>
            <div>
              <DownloadOutlined />
            </div>
          </div>
        </Card>
      </div>
      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
    </DashboardBackground>
  );
};

export default RemediationDetail;
