import React, { useContext, useState, useEffect } from "react";
import {
  Divider,
  Form,
  Input,
  Modal,
  Typography,
  Select,
  DatePicker,
  Spin,
} from "antd";
import CustomButton from "../CustomButton";
import { notificationsContext } from "../../context/Notifications";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import CloseCircleFilled from "@ant-design/icons/ClockCircleFilled"
import UploadIcon from "../../assets/Upload-icon.png";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const EvidenceModal = ({
  isEvidenceModalOpen,
  setIsEvidenceModalOpen,
  setRefreshTable,
}) => {
  const [form] = Form.useForm();
  const { Item } = Form;
  const [files, setFiles] = useState([]);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setIsEvidenceModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
    } catch (err) {}
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setFiles(files);
  }, [files]);
  return (
    <>
      <Modal
        title={
          <div className="w-full items-center flex justify-between">
            <Title level={4}>Evidence</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isEvidenceModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={450}
      >
        <Divider className="m-0" />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={customizeRequiredMark}
        >
          <style>
            {`

.document-uploader {
  border: 1px dashed gray;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  

  &.active {
    border-color: gray;
  }

  .upload-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
            }

  input[type="file"] {
    display: none;
  }
}
                      `}
          </style>
          <Item
            label="Upload Evidence"
            className="font-roboto mt-3"
            name="evidence"
            rules={[{ required: true, message: "Please select a file" }]}
          >
            <div className="drag-drop">
              <div
                className={`document-uploader bg-slate-100 ${
                  files.length > 0 ? "upload-box active" : "upload-box"
                }`}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                <>
                  <div className="upload-info h-25">
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "50px",
                        }}
                      >
                        <img width={40} src={UploadIcon} />
                      </div>
                      <p style={{ color: "black", fontWeight: "500" }}>
                        Drag & drop files or&nbsp;
                        <input
                          type="file"
                          hidden
                          id="browse"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          accept=".pdf,.cvs"
                        />
                        <label
                          htmlFor="browse"
                          style={{
                            textDecoration: "underline",
                            color: "#0000FF",
                            cursor: "pointer",
                          }}
                        >
                          Browse
                        </label>
                      </p>
                      <p style={{ fontSize: "12px", color: "grey" }}>
                        Supported Format: PDF, CSV,
                      </p>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </Item>
          {files.length > 0 && (
            <Item label="Uploaded file" className="font-roboto">
              {files.map((file, index) => (
                <div
                  className="w-full rounded-md h-15 flex p-5 justify-between"
                  style={{
                    border: "1px solid #E0E0E0",
                    borderBottom: "3px solid #483EA8",
                  }}
                  key={index}
                >
                  <div className="file-info">
                    <p className="font-roboto">{file.name}</p>
                    {/* <p>{file.type}</p> */}
                  </div>
                  <CloseCircleOutlined
                    onClick={() => handleRemoveFile(index)}
                          className="text-[18px] cursor-pointer"
                          style={{color:"gray"}}
                       
                  />
                </div>
              ))}
            </Item>
          )}

          <Item
            label="Moving Reason"
            className="font-roboto"
            name="due_date"
            rules={[{ required: true, message: "Please enter a reason" }]}
          >
            <TextArea
              rows={4}
              style={{ resize: "none" }}
              placeholder="Enter reason"
              maxLength={6}
            />
          </Item>

          <Item>
            <CustomButton
              text={isLoading ? <Spin /> : "Mark as Resolved"}
              type="primary"
              htmlType="submit"
              className="h-11 mt-4"
              disabled={isLoading}
            />
          </Item>
        </Form>

        {/* <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        /> */}
      </Modal>
    </>
  );
};

export default EvidenceModal;
