import React from "react";
import { Divider, Modal, Typography } from "antd";
import CustomButton from "../CustomButton";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const AssessmentSuccessModal = ({
  isSuccessModalOpen,
  setIsSuccessModalOpen,
}) => {
  const handleCancel = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <>
      <Modal
        title={
          <div className="flex gap-5 items-center mt-3">
            <CheckCircleOutlined className="text-green-500 text-2xl" />
            <Title level={5} className="!m-0">
              Assessment Scheduled
            </Title>
          </div>
        }
        open={isSuccessModalOpen}
        onCancel={handleCancel}
        centered
        footer={null}
        width={400}
      >
        <div className="my-3 ml-11">
          <Text>Assessment Scheduled Successfully</Text>
        </div>

        <div className="flex w-full justify-end">
          <div className="w-16">
            <CustomButton
              text="Close"
              type="primary"
              className="h-8 my-3"
              onClick={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AssessmentSuccessModal;
