import React, { useEffect, useState } from "react";
import { Breadcrumb, Progress, Spin, Tabs, Tooltip, Typography } from "antd";
import {
  AssessmentDetailBreadCrumbList,
  AssessmentStatus,
} from "../utils/constants";
import CustomButton from "../components/CustomButton";
import Introduction from "../components/AssessmentSteps/Introduction";
import assessmentApi from "../api/assessmentAxios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import QuestionsWrapper from "../components/AssessmentSteps/QuestionsWrapper";
import { useContext } from "react";
import { notificationsContext } from "../context/Notifications";
import TokenExpireModal from "../components/TokenExpireModal";
import AssessmentSubmitModal from "../components/Assessment/AssessmentSubmitModal";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

const AssessmentDetail = () => {
  const { Title, Text } = Typography;
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { api: notificationAPI } = useContext(notificationsContext);
  const standardName = searchParams.get("standard");
  const assessmentId = +searchParams.get("assessment");
  const [assessmentCurrentStatus, setAssessmentCurrrentStatus] = useState(
    searchParams.get("status")
  );
  const [steps, setSteps] = useState(["Introduction"]);
  const [currentStep, setCurrentStep] = useState("0");
  const [introduction, setIntroduction] = useState({});
  const [questionnaire, setQuestionnaire] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [updatedAnswers, setUpdatedAnswers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitWarningModalOpen, setIsSubmitWarningModalOpen] =
    useState(false);
  const [isSubmitSuccessModalOpen, setIsSubmitSuccessModalOpen] =
    useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [valueToAddInProgress, setValueToAddInProgress] = useState(0);
  const [totalQuestionsOfCurrentTab, setTotalQuestionsOfCurrentTab] =
    useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [fileLists, setFileLists] = useState({});
  const [questionStatus, setQuestionStatus] = useState({});

  useEffect(() => {
    (async () => await getStandardDetail())();
  }, []);

  useEffect(() => {
    setTotalQuestionsOfCurrentTab(
      questionnaire[steps[+currentStep]?.title]?.length
    );
    setCurrentQuestionIndex(0);
  }, [currentStep]);

  const getStandardDetail = async () => {
    try {
      setIsLoading(true);
      const standardDetail = await assessmentApi.get(`/standard/${id}`);

      const questionnaire = await assessmentApi.get(
        `/questionnaire/standard/${id}?assessment_id=${assessmentId}`
      );

      const newSteps = [
        {
          key: "0",
          label: <Tooltip title="INTRODUCTION">INTRODUCTION</Tooltip>,
          title: "INTRODUCTION",
          disabled: false,
        },
      ];

      questionnaire?.data?.data?.categories?.map((category, index) =>
        newSteps.push({
          key: (index + 1)?.toString(),
          label: (
            <Tooltip title={category}>
              {`${category?.split(" ")[0]}...`}
            </Tooltip>
          ),
          title: category,
          disabled:
            assessmentCurrentStatus === AssessmentStatus.NOT_YET_STARTED
              ? true
              : false,
        })
      );

      const totalQuestions = Object.values(
        questionnaire?.data?.data?.questionnaire
      ).reduce((total, array) => total + array.length, 0);

      setValueToAddInProgress((100 / totalQuestions).toFixed(2));
      setProgressValue(Math.round(100 / totalQuestions));
      // setProgressValue((100 / totalQuestions).toFixed(2));
      setSteps(newSteps);
      setIntroduction(standardDetail?.data?.data);
      setQuestionnaire(questionnaire?.data?.data?.questionnaire);
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const handleNext = async () => {
    if (
      currentStep === "0" &&
      assessmentCurrentStatus == AssessmentStatus.NOT_YET_STARTED
    ) {
      try {
        setIsLoading(true);
        await assessmentApi.put(`/assessment/${assessmentId}`, {
          status: AssessmentStatus.IN_PROGRESS,
        });
        setAssessmentCurrrentStatus(AssessmentStatus.IN_PROGRESS);
        setSteps((prev) => prev.map((step) => ({ ...step, disabled: false })));
        setCurrentStep((prev) => (+prev + 1).toString());
        setIsLoading(false);
      } catch (err) {
        if (err?.response?.data?.token_expire === true) {
          setIsLoading(false);
          setIsTokenExpiredModalOpen(true);
        } else {
          setIsLoading(false);
          notificationAPI.error({
            message: "Error",
            description: err?.message,
            placement: "top",
            duration: 4,
          });
        }
      }
    } else if (+currentStep < steps.length - 1) {
      setCurrentStep((prev) => (+prev + 1).toString());
    } else {
      setIsSubmitWarningModalOpen(true);
    }
  };

  const submitAssessmentConfirmation = async () => {
    try {
      setIsSubmitWarningModalOpen(false);
      await assessmentApi.put(`/assessment/${assessmentId}`, {
        status: AssessmentStatus.COMPLETED,
      });
      setIsSubmitSuccessModalOpen(true);
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsTokenExpiredModalOpen(true);
      } else {
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const submitAssessmentSuccess = (status) => {
    setIsSubmitSuccessModalOpen(status)
    navigate("/dashboard/assessment");
  }
  const handleSaveQuestionnaire = async () => {
    try {
      setIsSaving(true);

      await assessmentApi.post(
        "/assessment/bulk-save-assessment-response",
        updatedAnswers
      );

      notificationAPI.success({
        message: "Success",
        description: "Assessment Saved Successfully",
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
    setIsSaving(false);
  };

  return (
    <div className="p-5">
      <Breadcrumb items={AssessmentDetailBreadCrumbList} />

      <div className="flex justify-between items-center mt-3">
        <Title level={4} className="!m-0">
          {standardName} Assessment
        </Title>
        {currentStep === '0' && assessmentCurrentStatus == AssessmentStatus.NOT_YET_STARTED && <div className="w-40">
          <CustomButton
            text={"Start Assessment"}
            type="primary"
            className={`bg-appLightBlue ${currentStep < steps.length - 1 ? "rounded-lg" : "rounded-2xl"
              } h-9`}
            onClick={handleNext}
          />
        </div>}
        {currentStep === steps.length - 1 && <div className="w-40">
          <CustomButton
            text={"Submit Assessment"}
            type="primary"
            className={`bg-appLightBlue ${currentStep < steps.length - 1 ? "rounded-lg" : "rounded-2xl"
              } h-9`}
            onClick={handleNext}
          />
        </div>}
      </div>

      <div className="w-[80vw] mt-5">
        <Tabs
          activeKey={currentStep.toString()}
          items={steps}
          onChange={(val) => {
            setCurrentStep(val);
          }}
        />
      </div>

      <div className="max-h-[60vh] overflow-y-auto bg-[#FAFAFA] p-4">
        {currentStep !== "0" && (
          <div className="flex gap-1 items-center">
            <Text className="w-40">
              Question {currentQuestionIndex + 1} of{" "}
              {totalQuestionsOfCurrentTab}
            </Text>
            <Progress percent={progressValue} />
            <div>
              <CustomButton
                text="Save Assessment"
                type="primary"
                className="h-10 bg-appLightBlue rounded-md"
                onClick={handleSaveQuestionnaire}
              />
            </div>
          </div>
        )}

        <div>
          {currentStep === "0" ? (
            isLoading ? (
              <Spin size="large" className="w-full" />
            ) : (
              <Introduction introduction={introduction} />
            )
          ) : (
            <QuestionsWrapper
              name={steps[currentStep]?.title}
              data={questionnaire[steps[+currentStep]?.title]}
              setUpdatedAnswers={setUpdatedAnswers}
              handleSaveQuestionnaire={handleSaveQuestionnaire}
              assessmentId={assessmentId}
              isSaving={isSaving}
              stepsCount={steps.length}
              currentStep={currentStep}
              setProgressValue={setProgressValue}
              valueToAddInProgress={valueToAddInProgress}
              currentQuestionIndex={currentQuestionIndex}
              setCurrentQuestionIndex={setCurrentQuestionIndex}
              answers={answers}
              setAnswers={setAnswers}
              fileLists={fileLists}
              setFileLists={setFileLists}
              questionStatus={questionStatus}
              setQuestionStatus={setQuestionStatus}
            />
          )}
        </div>
      </div>
      <AssessmentSubmitModal
        isModalOpen={isSubmitWarningModalOpen}
        setIsModalOpen={setIsSubmitWarningModalOpen}
        title="Are you sure to submit the assessment?"
        icon={<WarningOutlined className="text-yellow-500 text-2xl" />}
        bodyText="Once you submit the assessment, You won't be able to edit it."
        buttons={[
          {
            text: "Submit",
            type: "primary",
            onClick: () => submitAssessmentConfirmation(),
          },
          {
            text: "Close",
            type: "primary",
            onClick: () => setIsSubmitWarningModalOpen(false),
          },
        ]}
      />
      <AssessmentSubmitModal
        isModalOpen={isSubmitSuccessModalOpen}
        setIsModalOpen={submitAssessmentSuccess}
        title="Assessment Submitted"
        icon={<CheckCircleOutlined className="text-green-500 text-2xl" />}
        bodyText="Your Assessment is submitted successfully."
        buttons={[
          {
            text: "Close",
            type: "primary",
            onClick: () => submitAssessmentSuccess(false),
          },
        ]}
      />
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </div>
  );
};

export default AssessmentDetail;
