import React, { useContext, useState } from "react";
import { Typography, Form, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import api from "../api/axios";
import { AuthContext } from "../context/AuthContext";
import { notificationsContext } from "../context/Notifications";
import SignUpCode from "./SignUpCode";
import SetUpPassword from "../pages/SetUpPassword";

const { Title, Text, Link } = Typography;

const SignInForm = ({ handleNextStep }) => {
  const { Item } = Form;
  const navigate = useNavigate();
  const { login , setSessionFor2FA } = useContext(AuthContext);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [loginOtp, setLoginOtp] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false)
  const [temporaryUserData, setTemporaryUserData] = useState({email:""})
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState()
  const switchScreen = () => {
    setLoginOtp(true)
  }

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const res = await api.post("/login", values);
      setIsLoading(false);
      if (res?.data?.data?.challengeName === "NEW_PASSWORD_REQUIRED") {
        setTemporaryUserData({ email: res?.data.data?.email })
        setIsNewPasswordRequired(true)
      }
      else if(res?.data?.data?.challengeName === "EMAIL_OTP" || res?.data?.data?.challengeName === "SMS_MFA")
      {
        setSessionFor2FA(res?.data?.data);
        handleNextStep(2)
      }else{
        login(res?.data);
      }
    } catch (err) {
      if (err?.response?.data?.error === "UserNotConfirmedException") {
        switchScreen()
        setIsLoading(false); 
        setEmail(values.email)      
     }
       else {
         setIsLoading(false);
         notificationAPI.error({
           message: "Error",
           description: err?.response?.data?.error,
           placement: "top",
           duration: 4,
         });
      } 
    }
  };
  return (
    !loginOtp ? (
      isNewPasswordRequired ? (<SetUpPassword temporaryUserData={temporaryUserData} setIsNewPasswordRequired={setIsNewPasswordRequired} />)
        :(
      <CustomCard>
      <Title level={2} className="!font-bold">
        Sign In
      </Title>

      <Form onFinish={onFinish} layout="vertical">
        <Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                  )
                ) {
                  return Promise.reject(new Error("The email is not valid!"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="test@test.com" size="large" />
        </Item>

        <Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password placeholder="Enter your password" size="large" />
        </Item>

        <Item>
          <CustomButton
            text={isLoading ? <Spin /> : "Sign In"}
            type="primary"
            htmlType="submit"
            disabled={isLoading}
          />
        </Item>
      </Form>

      <div className="w-full flex justify-between">
        <Link
          className="!underline !text-appBlue"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password?
        </Link>
        <div className="flex gap-1">
          <Text type="secondary">Already have an account?</Text>
          <Link
            className="!underline !text-appBlue"
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </Link>
        </div>
      </div>
          </CustomCard>
        )
    ) : (<SignUpCode email={email} setLoginOtp={setLoginOtp} /> )
  );
};

export default SignInForm;
