import axios from "axios";

const assessmentApi = axios.create({
  baseURL: process.env.REACT_APP_ASSESSMENT_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

assessmentApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      throw Error("Valid token is required!");
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default assessmentApi;
