import React, { useEffect, useState, useContext } from "react";
import { Table, Typography, Spin, Tag, Tooltip } from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import CustomSearch from "../components/CustomSearch";
import CustomButton from "../components/CustomButton";
import { notificationsContext } from "../context/Notifications";
import { useNavigate } from "react-router-dom";
import TokenExpireModal from "../components/TokenExpireModal";
import AssignModal from "../components/Remediation/AssignModal";
import EvidenceModal from "../components/Remediation/EvidenceModal";
import assessmentApi from "../api/assessmentAxios";

const { Title } = Typography;

const Remediation = () => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    (async () => await fetchData())();
  }, [refreshTable, searchText]);

  const fetchData = async (
    currentPage = 1,
    pageSize = 10,
    sort_by,
    sort_order
  ) => {
    try {
      setIsLoading(true);
      const params = {
        page_number: currentPage,
        page_limit: pageSize,
        sort_by: sort_by,
        sort_order: sort_order,
      };

      if (searchText.length > 0) params.search_text = searchText;

      const remediations = await assessmentApi.get("/remediation", { params });
      setData(remediations?.data?.data?.rows);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.message,
        placement: "top",
        duration: 4,
      });
    }
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sort_by = sorter.field; // Get the field to sort by
    const sortOrder = sorter.order;
    const sort_order =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
        ? "desc"
        : undefined;
    setTableParams({
      pagination,
    });
    await fetchData(
      pagination.current,
      pagination.pageSize,
      sort_by,
      sort_order
    );
  };

  const columns = [
    {
      title: "Requirement",
      dataIndex: "questionnaire",
      key: "questionnaire",
      render: (text) => {
        const question = text?.question_statement || "";
        const truncatedQuestion =
          question.length > 100 ? `${question.slice(0, 40)}...` : question;
        return (
          <Tooltip title={question}>
            <span className="capitalize cursor-pointer">
              {truncatedQuestion}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: true,
      render: (_, { status }) => {
        let color = "";

        status === "In Progress" ? (color = "gold") : (color = "green");

        return (
          <Tag
            color={color}
            key={status}
            className="rounded-2xl font-semibold text-sm"
            bordered={false}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Missing Control",
      dataIndex: "missing_control",
      key: "missing_control",
      sorter: true,
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Evidence",
      dataIndex: "evidence",
      key: "evidence",
      render: (text) => (
        <span
          className="capitalize text-cyan-500 cursor-pointer"
          onClick={() => setIsEvidenceModalOpen(true)}
        >
          {text.map((evi) => (
            <p>{evi}</p>
          ))}
        </span>
      ),
    },

    {
      title: "Remediation Severity",
      dataIndex: "remediation_severity",
      key: "remediation_severity",
      sorter: true,
      render: (text) => <span className="capitalize ">{text}</span>,
      align: "center",
    },
    {
      title: "Assign",
      dataIndex: "assigned_to",
      key: "assign",
      render: (_, record) => {
        return record?.assigned_to === null ? (
          <a
            className="capitalize text-cyan-500"
            onClick={() => setIsAssignModalOpen(true)}
          >
            {record?.assigned_to}
          </a>
        ) : (
          <span className="capitalize">{record?.assigned_to}</span>
        );
      },
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render: (assigned_date) => {
        const date = new Date(assigned_date);
        const formattedDate = date.toLocaleDateString("en-GB");
        return formattedDate;
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (due_date) => {
        const date = new Date(due_date);
        const formattedDate = date.toLocaleDateString("en-GB");
        return formattedDate;
      },
    },
    {
      title: "Actions",
      key: "action",

      render: (_, record) => (
        <div className="w-full flex justify-center">
          <div className="w-14">
            <CustomButton
              text="View"
              variant="outlined"
              className="h-7"
              onClick={() =>
                navigate(
                  `/dashboard/remediation/remediation-detail/${record.id}`
                )
              }
            />
          </div>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center">
        <CustomSearch setSearchText={setSearchText} />
      </div>
      <div className="h-[80vh] m-5 bg-white px-5 pt-5 overflow-scroll overflow-x-hidden">
        <Title level={3}>Remediation</Title>
        <div className="mt-6">
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              ...tableParams.pagination,
              position: ["bottomCenter"],
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
      <AssignModal
        isAssignModalOpen={isAssignModalOpen}
        setIsAssignModalOpen={setIsAssignModalOpen}
        setRefreshTable={setRefreshTable}
      />
      <EvidenceModal
        isEvidenceModalOpen={isEvidenceModalOpen}
        setIsEvidenceModalOpen={setIsEvidenceModalOpen}
        setRefreshTable={setRefreshTable}
      />
    </DashboardBackground>
  );
};
export default Remediation;
