
import CryptoJS from "crypto-js";
// Encrypt function with IV
export const encryptDataWithIV = (data, secretKey) => {
  // Generate a random IV
  const iv = CryptoJS.lib.WordArray.random(16);
  // Encrypt the data
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  // Return IV and ciphertext as JSON
  return {
    iv: iv.toString(CryptoJS.enc.Hex), // Convert IV to a hex string
    ciphertext: encrypted.ciphertext.toString(CryptoJS.enc.Hex), // Convert ciphertext to a hex string
  };
};







