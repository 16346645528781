import React, { useEffect, useContext } from "react";
import { Divider, Form, Input, Modal, Typography } from "antd";
import WarningOutlined from "@ant-design/icons/WarningOutlined";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const { Title } = Typography;

const TokenExpireModal = ({
  isTokenExpiredModalOpen,
  setIsTokenExpiredModalOpen,
}) => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  return (
    <>
      <Modal
        open={isTokenExpiredModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={400}
        height={200}
      >
        <div className="flex justify-start items-start gap-4">
          <div>
            <WarningOutlined className="text-xl text-red-500 mt-1" />
          </div>
          <div>
            <p className="text-lg ">Your Session is expired.</p>
            <p className="text-sm">Please login again.</p>
          </div>
        </div>
        <div className="w-full flex justify-end mt-4">
          <CustomButton
            text={"Log out"}
            type="primary"
            onClick={() => { logout(), navigate('/signin') }}
            className={"h-8 w-[70px]"}
          />
        </div>
      </Modal>
    </>
  );
};

export default TokenExpireModal;
