import React from "react";
import { Card } from "antd";

const CustomCard = ({ children }) => {
  return (
    <Card bordered={false} className="h-fit py-3 rounded-3xl w-[450px]">
      {children}
    </Card>
  );
};

export default CustomCard;
