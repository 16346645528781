import React, { useContext, useEffect, useState } from "react";
import {
  CloseOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Typography, Upload, Input, Tooltip, Select, Tag, Spin } from "antd";
import { MAX_FILE_SIZE, QuestionStatus } from "../../utils/constants";
import assessmentApi from "../../api/assessmentAxios";
import aiApi from "../../api/axiosAI";
import { notificationsContext } from "../../context/Notifications";

const { Title, Text } = Typography;
const { TextArea } = Input;

  const statusColors = {
    "Not In Place": "red",
    "Not Applicable": "gray",
    "In Place": "green",
    "Partially In Place": "orange",
  };
 

const Questions = ({
  question,
  setUpdatedAnswers,
  assessmentId,
  answers,
  setAnswers,
  fileLists,
  setFileLists,
  questionStatus,
  setQuestionStatus,
}) => {
  const [isEditing, setIsEditing] = useState(question?.answer_data === null);
  const [warningMessages, setWarningMessages] = useState({});
  const [aiSuggestion, setAiSuggestion] = useState(null);
  const [isAiSuggestionGenerated, setIsAiSuggestionGenerated] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [status, setStatus] = useState();
  const currentStatus = questionStatus[question?.id];
  const color = statusColors[currentStatus] || "gray"; // Default color if no status is selected

  const suggestionsArray = [
    "This is just a placeholder suggestion",
    "This one as well",
    "Here comes another placeholder suggestion, late on it will be generated by AI",
  ];

  const fileTypes = [
    "application/pdf", // PDF files
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (DOCX)
    "application/msword", // Word (DOC)
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (XLSX)
    "application/vnd.ms-excel", // Excel (XLS)
    "text/csv", // CSV files
  ];

  useEffect(() => {
    if (!answers[question?.id]) {
      setAnswers((prev) => ({
        ...prev,
        [question?.id]: question?.answer_data?.answer,
      }));
    }

    if (!fileLists[question?.id]) {
      setFileLists((prev) => ({
        ...prev,
        [question?.id]: question?.answer_data?.attachments || [],
      }));
    }

    if (!questionStatus[question?.id]) {
      setQuestionStatus((prev) => ({
        ...prev,
        [question?.id]:
          question?.answer_data == null
            ? null
            : question?.answer_data?.status != null
            ? question?.answer_data?.status
            : null,
      }));
    }
    (async () => {
      await getAiSuggestion();
    })();
  }, [question]);

  const getAiSuggestion = async () => {
    try {
      setIsAiSuggestionGenerated(true);
      var bodyFormData = new FormData();
      bodyFormData.append("input_question", question.question_statement);
      const response = await aiApi.post("/text_completion", bodyFormData);
      setIsAiSuggestionGenerated(false);
      setAiSuggestion(response?.data?.outputObject.generated_answer);
    } catch (error) {
      setIsAiSuggestionGenerated(false);
      console.error({ error }, "========= AI Error ==========");
      notificationAPI.error({
        message: "Error",
        description:
          "Something went wrong while generating AI suggestion, try again later!",
        placement: "top",
        duration: 4,
      });
    }
  };
  const handleEditClick = () => {
    setIsEditing(true);
    setAnswers((prev) => ({
      ...prev,
      [question.id]: prev[question.id] || question.answer_data?.answer || "",
    }));
    setWarningMessages((prev) => ({ ...prev, [question.id]: "" }));
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    setWarningMessages((prev) => ({ ...prev, [question.id]: "" }));

    setUpdatedAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question.id
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex
            ? { ...item, answer: answers[question.id] }
            : item
        );
      }

      return [
        ...prev,
        {
          answer: answers[question.id],
          question_id: question?.id,
          assessment_id: assessmentId,
          attachments: fileLists[question.id],
          status: questionStatus[question.id],
        },
      ];
    });

    if (question.answer_data?.id) {
      await assessmentApi.put(
        `/assessment/update-assessment-response/${question.answer_data?.id}`,
        {
          answer: answers[question.id],
          attachments: [...fileLists[question.id]],
        }
      );
    }
  };

  const handleUploadClick = () => {};

  const handleStatusChange = (statusValue) => {
    setQuestionStatus((prev) => ({
      ...prev,
      [question.id]: statusValue,
    }));
    setSelectedStatus(true);

    setUpdatedAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question.id
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, status: statusValue } : item
        );
      }

      return [
        ...prev,
        {
          answer: answers[question.id],
          question_id: question?.id,
          assessment_id: assessmentId,
          attachments: [],
          status: statusValue,
        },
      ];
    });
  };

  const handleTextAreaChange = (e) => {
    setAnswers((prev) => ({
      ...prev,
      [question?.id]: e.target.value,
    }));

    setUpdatedAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question.id
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, answer: e.target.value } : item
        );
      }

      return [
        ...prev,
        {
          answer: e.target.value,
          question_id: question?.id,
          assessment_id: assessmentId,
          attachments: fileLists[question.id],
          status: questionStatus[question.id],
        },
      ];
    });
  };

  const handleAiSuggestionClick = (aiText) => {
    setAnswers((prev) => ({
      ...prev,
      [question?.id]: aiText,
    }));

    setUpdatedAnswers((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question.id
      );

      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex ? { ...item, answer: aiText } : item
        );
      }

      return [
        ...prev,
        {
          answer: aiText,
          question_id: question?.id,
          assessment_id: assessmentId,
          attachments: fileLists[question.id],
          status: questionStatus[question.id],
        },
      ];
    });
  };
  const uploadProps = {
    multiple: false,
    showUploadList: false,
    accept: ".pdf,.doc,.docx,.xls,.xlsx,.csv",
    beforeUpload: async (file) => {
      if (!fileTypes.includes(file.type)) {
        // Display warning if file type is incorrect
        setWarningMessages((prev) => ({
          ...prev,
          [question.id]: "Only PDF, Word, Excel, and CSV files are allowed.",
        }));
        return Upload.LIST_IGNORE; // Prevent upload
      }

      if (file.size / 1024 / 1024 > MAX_FILE_SIZE) {
        setWarningMessages((prev) => ({
          ...prev,
          [question.id]: "File size must be less than 5 MB.",
        }));
        return Upload.LIST_IGNORE;
      }

      try {
        const formData = new FormData();
        formData.append("attachment", file);

        const savedFile = await assessmentApi.post(
          "/assessment/upload-attachment",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setUpdatedAnswers((prev) => {
          const existingIndex = prev.findIndex(
            (item) => item.question_id === question.id
          );

          if (existingIndex !== -1) {
            return prev.map((item, index) =>
              index === existingIndex
                ? {
                    ...item,
                    attachments: [...item.attachments, savedFile?.data?.data],
                  }
                : item
            );
          }

          return [
            ...prev,
            {
              attachments: [...fileLists[question.id], savedFile?.data?.data],
              question_id: question?.id,
              assessment_id: assessmentId,
            },
          ];
        });
        setFileLists((prev) => ({
          ...prev,
          [question.id]: [...(prev[question.id] || []), savedFile?.data?.data],
        }));

        if (question.answer_data?.id) {
          const answer = answers[question.id] || " ";
          await assessmentApi.put(
            `/assessment/update-assessment-response/${question.answer_data?.id}`,
            {
              answer,
              attachments: [...fileLists[question.id], savedFile?.data?.data],
            }
          );
        } else {
          await assessmentApi.post(
            "/assessment/bulk-save-assessment-response",
            [
              {
                attachments: [...fileLists[question.id], savedFile?.data?.data],
                question_id: question?.id,
                assessment_id: assessmentId,
                status: questionStatus[question.id],
              },
            ]
          );
        }

        return false;
      } catch (err) {
        return false;
      }
    },
    onRemove: async (file) => {
      await assessmentApi.delete("/assessment/delete-attachment", {
        data: { attachment_key: file },
      });

      setFileLists((prev) => ({
        ...prev,
        [question.id]: prev[question.id].filter((item) => item !== file),
      }));

      setUpdatedAnswers((prev) =>
        prev.map((item) =>
          item.question_id === question.id
            ? {
                ...item,
                attachments: item.attachments.filter(
                  (attachment) => attachment !== file
                ),
              }
            : item
        )
      );

      if (question.answer_data?.id) {
        await assessmentApi.put(
          `/assessment/update-assessment-response/${question.answer_data?.id}`,
          {
            answer: answers[question.id],
            attachments: fileLists[question.id].filter((item) => item !== file),
          }
        );
      }
    },
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between gap-4">
        <div className="flex items-center gap-5">
          <Text className="!m-0">{question?.question_statement}</Text>
          <Tooltip title={question?.additional_note}>
            <InfoCircleOutlined className="text-gray-400" />
          </Tooltip>
        </div>

        <div className="flex flex-col gap-2 items-center">
          {selectedStatus === true ? (
            <Tag
              color={color}
              key={currentStatus}
              className="rounded-2xl font-semibold text-sm"
              bordered={false}
              onClick={() => setSelectedStatus(false)}
              style={{
                width: 150,
                textAlign:"center"
              }}
            >
              {currentStatus}
            </Tag>
          ) : (
            <Select
              value={questionStatus[question?.id]}
              placeholder="Select Status"
              onChange={handleStatusChange}
              options={QuestionStatus}
              style={{
                width: 150,
              }}
            />
          )}
        </div>
      </div>

      <div>
        <TextArea
          rows={4}
          value={answers[question?.id]}
          onChange={(e) => handleTextAreaChange(e)}
          className="w-full rounded-none border-none"
        />
        <div className="bg-white px-3 pb-3 flex flex-col">
          <div className="my-2">
            <Text type="secondary">AI Suggestions:</Text>
            {!isAiSuggestionGenerated && (
              <Tooltip title={"Regenrate Answer"}>
                <SyncOutlined
                  className="mx-2"
                  onClick={() => getAiSuggestion()}
                />{" "}
              </Tooltip>
            )}
          </div>
          <div>
            {isAiSuggestionGenerated ? (
              <Spin />
            ) : (
              <Tag
                style={{ cursor: "pointer" }}
                color="blue"
                className="rounded-sm text-wrap"
                onClick={() => handleAiSuggestionClick(aiSuggestion)}
              >
                {aiSuggestion}
              </Tag>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex gap-2 flex-wrap">
          {fileLists[question?.id]?.map((file, index) => (
            <div
              key={index}
              className="border border-appLightBlue flex items-center gap-1 px-1"
            >
              <LinkOutlined className="text-appLightBlue" />
              <Text className="text-xs text-appLightBlue">
                {file.name || file}
              </Text>
              <Button
                type="text"
                icon={<CloseOutlined className="!text-xs text-gray-500" />}
                onClick={() => uploadProps.onRemove(file)}
                className="h-6 !w-4"
              />
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-1">
          <Upload {...uploadProps}>
            <Button
              type="primary"
              className={`bg-appLightBlue`}
              onClick={handleUploadClick}
            >
              <LinkOutlined /> Attach
            </Button>
          </Upload>
          {warningMessages[question?.id] && (
            <Text type="danger" className="text-red-500">
              {warningMessages[question?.id]}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default Questions;
