import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Spin,
  Card,
  Avatar,
  Divider,
  Input,
  Table,
  Tag,
} from "antd";
import DashboardBackground from "../components/Dashboard/DashboardBackground";
import TokenExpireModal from "../components/TokenExpireModal";
import { AuthContext } from "../context/AuthContext";
import { UserOutlined } from "@ant-design/icons";
import { FormOutlined } from "@ant-design/icons";
import CustomButton from "../components/CustomButton";
import ChangePassword from "./ChangePassword";
import defaultPic from "../assets/defaultPic.jpg";
import InviteUserModal from "../components/InviteUserModal";
import { notificationsContext } from "../context/Notifications";

import api from "../api/axios";
const { Title } = Typography;

const ProfileDetail = () => {
  const { user, updateUser } = useContext(AuthContext);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isEditAble, setIsEditAble] = useState(false);
  const [isEditNameLoading, setIsEditNameLoading] = useState(false)
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(user?.data?.user?.name);
  const [data, setData] = useState([]);
  const [originalValue, setOriginalValue] = useState(user?.data?.user?.name);
  const [refreshTable, setRefreshTable] = useState(false);
  const userRole = user?.data?.user?.role
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  useEffect(() => {
    (async () => userRole === 'SUPER_ADMIN' && await fetchData())();
  }, [refreshTable]);

  const fetchData = async (currentPage = 1, pageSize = 10) => {
    try {
      setIsLoading(true);
      const params = {
        page_number: currentPage,
        page_limit: pageSize,
      };
      const res = await api.get(`/user/list-user-for-admin`, { params });
      setIsLoading(false);
      setData(res?.data?.data);
      setTableParams({
        pagination: {
          current: currentPage,
          pageSize,
          total: res?.data?.data?.count,
        },
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.response?.data?.error,
          placement: "top",
          duration: 4,
        });
      }
    }
  };
  const handleTableChange = async (pagination) => {
    setTableParams({
      pagination,
    });
    await fetchData(pagination.current, pagination.pageSize);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span className="capitalize">{text === "null" ? "N/A" : text}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <span className="capitalize">{text}</span>,
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   dataIndex: "status",
    //   render: (_, { status }) => {
    //     let color = "";
    //     status === "Not Active" ? (color = "yellow") : (color = "green");

    //     return (
    //       <Tag
    //         color={color}
    //         key={status}
    //         className="rounded-2xl font-semibold text-sm"
    //         bordered={false}
    //       >
    //         {status}
    //       </Tag>
    //     );
    //   },
    // },
  ];
  console.log(data)
  const handleNameUpdate = async () => {
    try {
      setIsEditNameLoading(true)
      const response = await api.put(`/user/${user?.data?.user?.id}`, {
        name: value,
      });
      setIsEditNameLoading(false)
      setIsEditAble(false)
      updateUser({ name: value });
      setOriginalValue(value);
      notificationAPI.success({
        message: "User name updated successfully",
        placement: "top",
        duration: 4,
      });
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsEditNameLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsEditNameLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.message,
          placement: "top",
          duration: 4,
        });
      }
    }
  }
  const handleEditClick = () => {
    setIsEditAble(true);
    setOriginalValue(value);
  };

  const handleCancelClick = () => {
    setIsEditAble(false);
    setValue(originalValue);
  };

  return (
    <DashboardBackground>
      <div className="h-16 bg-white px-5 flex justify-between items-center"></div>
      <div className="h-[80vh]  flex flex-col overflow-y-scroll">
        <div className="m-5 bg-white px-5 pt-5 flex flex-col">
          <Title level={3}>Profile</Title>
          <style>
            {` .custom-spin .ant-spin-dot {
              color: white;
            }`}
          </style>
          <Card className="h-[20vh] mt-4 ml-1 mr-1 bg-white pt-5">
            <div className="w-full flex flex-row justify-between items-center">
              <div className="w-1/2 flex flex-row gap-3 items-center">
                <div>
                  <Avatar size="large" src={defaultPic} />
                </div>
                <div className="text-[17px] font-semibold">
                  {user?.data?.user?.name}
                </div>
              </div>
              <div className="flex flex-row gap-3 w-72 items-center">
                <CustomButton
                  text="Change Password"
                  variant="outlined"
                  className="h-11"
                  onClick={() => setIsChangePasswordModalOpen(true)}
                />
                {isEditAble ? (
                  value !== originalValue ? (
                    <CustomButton
                      text={
                        isEditNameLoading ? (
                          <Spin className="custom-spin" />
                        ) : (
                          <span>Save</span>
                        )
                      }
                      type="primary"
                      htmlType="submit"
                      className="h-11"
                      onClick={handleNameUpdate}
                    />
                  ) : (
                    <CustomButton
                      text={"Cancel"}
                      type="primary"
                      htmlType="submit"
                      className="h-11"
                      onClick={handleCancelClick}
                    />
                  )
                ) : (
                  <CustomButton
                    text={
                      <>
                        <FormOutlined style={{ marginRight: 8 }} />
                        <span>Edit</span>
                      </>
                    }
                    type="primary"
                    htmlType="submit"
                    className="h-11"
                    onClick={() => handleEditClick()}
                  />
                )}
                {/* <CustomButton
                  text={
                    <div>
                      {isEditAble ? null : (
                        <FormOutlined style={{ marginRight: 8 }} />
                      )}
                      {isEditAble ? (
                        value !== originalValue ? (
                          isEditNameLoading ? (
                            <Spin className="custom-spin" />
                          ) : (
                            <span onClick={handleNameUpdate}>Save</span>
                          )
                        ) : (
                          <span onClick={() => setIsEditAble(false)}>
                            Cancel
                          </span>
                        )
                      ) : (
                        <span onClick={() => handleEditClick()}>Edit</span>
                      )}
                    </div>
                  }
                  type="primary"
                  htmlType="submit"
                  className="h-11"
                /> */}
              </div>
            </div>
          </Card>
          <Card className="mr-1 ml-1 pt-5 h-[20vh] mb-5">
            <div className="w-full flex flex-row justify-between items-center gap-4">
              <div className="w-1/2">
                <p className="mb-4 text-[15px] font-semibold">Full Name</p>
                {isEditAble === true ? (
                  <Input
                    type="text"
                    name="name"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                ) : (
                  <div className="text-gray-500 border-b border-gray-300">
                    {user?.data?.user?.name}
                  </div>
                )}
              </div>
              <div className="w-1/2">
                <p className="mb-4 text-[15px] font-semibold">Email</p>
                <div className="text-gray-500 border-b border-gray-300">
                  {user?.data?.user?.email}
                </div>
              </div>
            </div>
          </Card>
        </div>
        {userRole === "SUPER_ADMIN" && (
          <div className="ml-5 mr-5 mb-15 pb-15">
            <div className=" mt-2 bg-white px-5 pt-5">
              <div className="flex flex-row w-full justify-between items-center">
                <div>
                  <Title level={3}>Tenant Users</Title>
                </div>
                <div>
                  <CustomButton
                    text="Invite User"
                    type="primary"
                    className="h-11"
                    onClick={() => setIsInviteUserModalOpen(true)}
                  />
                </div>
              </div>
              <div className="mt-6">
                <Table
                  columns={columns}
                  dataSource={data}
                  loading={isLoading}
                  pagination={{
                    ...tableParams.pagination,
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <TokenExpireModal isTokenExpiredModalOpen={isTokenExpiredModalOpen} />
      <InviteUserModal
        isInviteUserModalOpen={isInviteUserModalOpen}
        setIsInviteUserModalOpen={setIsInviteUserModalOpen}
        data={data}
        setData={setData}
      />
      <ChangePassword
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
        email={user?.data?.user?.email}
      />
    </DashboardBackground>
  );
};

export default ProfileDetail;
