import React, { useContext, useState } from "react";
import { Typography, Form, Input, Button, Spin } from "antd";

import CustomCard from "./CustomCard";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import { notificationsContext } from "../context/Notifications";

const { Title, Text, Link } = Typography;

const SignUpCode = ({ email, setLoginOtp = () =>{} }) => {
  const navigate = useNavigate();
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [code, setCode] = useState("");
    const [resendingLoading, setResendingLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (value) => {
    if (value.length === 6) {
      setCode(value);
      setIsDisabled(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await api.post("/confirm-signup", {
        email,
        confirmationCode: code,
      });
      setIsLoading(false);
      setLoginOtp(false);
      navigate("/signin");
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  };
    const handleResendCode = async() => {
      try {
      setResendingLoading(true);
      const values = {
        email: email,
        action:"SIGN-UP"
      }
        const res = await api.post("/resend-code", values);
        setResendingLoading(false);
       notificationAPI.success({
         message: "Code is resend successfully",
         placement: "top",
         duration: 4,
       });
      } catch (err) {
        setResendingLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err?.response?.data?.error,
        placement: "top",
        duration: 4,
      });
    }
  }

  return (
    <CustomCard>
      <Title level={2} className="!font-bold">
        We have sent you a code
      </Title>

      <Text type="secondary">The code was sent to {email}</Text>

      <div className="flex justify-center mx-14 my-10">
        <Input.OTP
          length={6}
          size="large"
          onChange={(val) => handleChange(val)}
        />
      </div>

      <CustomButton
        text={isLoading ? <Spin /> : "Confirm"}
        type="primary"
        onClick={handleSubmit}
        disabled={isDisabled || isLoading}
      />

      <div className="flex flex-col items-center w-full mt-8 gap-1">
        <Text type="secondary">Didn’t receive the code?</Text>
        <Link className="!underline !text-appBlue" onClick={handleResendCode}>
          {resendingLoading ? <Spin size="small" /> : "Resend code"}
        </Link>
      </div>
    </CustomCard>
  );
};

export default SignUpCode;
