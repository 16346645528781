import { notification } from "antd";
import { createContext } from "react";

export const notificationsContext = createContext();

export const NotificationsContextProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <notificationsContext.Provider value={{ api, contextHolder }}>
      {children}
    </notificationsContext.Provider>
  );
};
