import React, {useEffect} from "react";
import {
  Divider,
  Form,
  Input,
  Modal,
  Typography,
} from "antd";
import AimOutlined from "@ant-design/icons/AimOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";

const { Title } = Typography;


const ViewAssetDetailModal = ({
  isAssetDetailModalOpen,
  setIsAssetDetailModalOpen,
  selectedAsset,
}) => {
  const [form] = Form.useForm();
  const { Item } = Form;

  const handleCancel = () => {
    form.resetFields();
    setIsAssetDetailModalOpen(false);
  };

  // const onFinish = async (values) => {
  //   try {
  //     console.log(values);
  //   } catch (err) {
  //     console.log({ err });
  //   }
  // };
  
  useEffect(() => {
    if (selectedAsset) {
      form.setFieldsValue({
        assetName: selectedAsset?.name,
        type: selectedAsset?.type,
        address: selectedAsset?.ip,
        vendor: selectedAsset?.vendor,
        location: selectedAsset?.appliance_sites,
      });
    }
  }, [selectedAsset, form]);


  return (
    <>
      <Modal
        title={
          <div className="w-full items-start flex justify-between">
            <Title level={4}>Details</Title>
            <CloseCircleOutlined
              onClick={() => handleCancel()}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isAssetDetailModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={450}
      >
        <Divider className="m-0" />

        <Form
          className="mt-5"
          form={form}
          // onFinish={onFinish}
          layout="vertical"
        >
          <Item
            label="Asset Name"
            className="font-roboto font-bold"
            name="assetName"
          >
            <Input
              name="assetName"
              size="large"
              disabled
              className="rounded-3xl font-normal"
            />
          </Item>

          <Item label="Type" className="font-roboto font-bold" name="type">
            <Input disabled size="large" className="rounded-3xl font-normal" />
          </Item>

          <Item
            label="Address"
            className="font-roboto font-bold"
            name="address"
          >
            <Input
              name="address"
              size="large"
              className="rounded-3xl font-normal"
              disabled
            />
          </Item>
          <Item
            label="Location"
            className="font-roboto font-bold"
            name="location"
          >
            <Input
              size="large"
              suffix={<AimOutlined style={{ color: "grey" }} />}
              disabled
              name="location"
              value={Array.isArray(location) ? location.join(", ") : ""}
              className="rounded-3xl font-normal"
            />
          </Item>

          <Item
            label="Vendor"
            className="font-roboto font-bold"
            name="vendor"
          >
            <Input
              size="large"
              name="vendor"
              controls={false}
              style={{ width: "400px" }}
              disabled
              className="rounded-3xl font-normal"
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};
export default ViewAssetDetailModal;
