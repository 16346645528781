import React from "react";
import { Input } from "antd";

const { Search } = Input;

const CustomSearch = ({ setSearchText }) => {
  return (
    <div id="CustomSearch" className="w-2/5">
      <Search
        placeholder="Search"
        allowClear
        enterButton="Search"
        className="w-full"
        onSearch={(text) => {
          setSearchText(text);
        }}
        onClear={() => setSearchText("")}
      />
    </div>
  );
};

export default CustomSearch;
