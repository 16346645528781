import React, { useState, useContext, useEffect } from "react";
import { Divider, Form, Input, Modal, Typography, Spin } from "antd";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import CustomButton from "../components/CustomButton";
import "react-phone-number-input/style.css";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import api from "../api/axios";

const { Title } = Typography;

const ChangePassword = ({
  isChangePasswordModalOpen,
  setIsChangePasswordModalOpen,
  email,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [passwordError, setPasswordError] = useState("");
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { Item } = Form;

  const handleCancel = () => {
    form.resetFields();
    setIsChangePasswordModalOpen(false);
  };

  const onFinish = async (values) => {
    const formatedData = {
      email: email,
      old_password: values?.old_password,
      new_password: values?.password,
    };
    setIsLoading(true);
    try {
      const res = await api.post("/change-password", formatedData);
      if (res) {
        form.resetFields();
        setIsChangePasswordModalOpen(false);
        setIsLoading(false);
        notificationAPI.success({
          message: "Password Updated successfully",
          placement: "top",
          duration: 4,
        });
      }
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.response?.data?.error,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  const validatePassword = (value) => {
    const errors = [];
    if (value.length < 8) errors.push("at least 8 characters long");
    if (!/[A-Z]/.test(value)) errors.push("1 uppercase letter");
    if (!/[a-z]/.test(value)) errors.push("1 lowercase letter");
    if (!/[0-9]/.test(value)) errors.push("1 number");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value))
      errors.push("1 special character");

    if (errors.length > 0) {
      setPasswordError(`Your password must be ${errors.join(", ")}.`);
    } else {
      setPasswordError("");
    }
  };
  return (
    <>
      <Modal
        title={
          <div className="w-full items-start  flex justify-between">
            <Title level={4}>Change Password</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isChangePasswordModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={470}
      >
        <Divider className="m-0" />
        <Form
          className="mt-5"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={customizeRequiredMark}
        >
          <style>
            {` .custom-spin .ant-spin-dot {
              color: white;
            }`}
          </style>
          <Item
            label="Enter Old Password"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Please enter your old password",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter Old Password"
              size="large"
              onChange={(e) => validatePassword(e.target.value)}
            />
          </Item>
          <Item
            label="Enter New Password"
            name="password"
            rules={[
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Please enter your new password")
                    );
                  }
                  validatePassword(value);
                  if (passwordError) {
                    return Promise.reject(new Error(passwordError));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              placeholder="Enter New Password"
              size="large"
              onChange={(e) => validatePassword(e.target.value)}
            />
          </Item>

          <Item
            label="Confirm New Password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password & Confirm Password do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" size="large" />
          </Item>
          <Item>
            <CustomButton
              text={
                isLoading ? <Spin className="custom-spin" /> : "Change Password"
              }
              type="primary"
              htmlType="submit"
              className="h-11 mt-4"
            />
          </Item>
        </Form>
        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </Modal>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </>
  );
};
export default ChangePassword;
