import React, { useState, useContext, useEffect } from "react";
import { Divider, Form, Input, Modal, Typography, Select, Spin } from "antd";
import AimOutlined from "@ant-design/icons/AimOutlined";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import CustomButton from "../CustomButton";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import newAsset from "../../api/assetAxios";
import TokenExpireModal from "../TokenExpireModal";
import { notificationsContext } from "../../context/Notifications";

const { Title } = Typography;
const { Option } = Select;

const AddAssetModal = ({
  setIsAddAssetModalOpen,
  isAddAssetModalOpen,
  setData,
  data,
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isAddAssetLoading, setIsAddAssetLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [form] = Form.useForm();
  const { Item } = Form;
  let scrollTimeout = null;

  const handleScroll = () => {
    setIsScrolling(true);
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsAddAssetModalOpen(false);
  };

  useEffect(() => {
    const modalContent = document.querySelector(".ant-modal-body");
    if (modalContent) {
      modalContent.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (modalContent) {
        modalContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isAddAssetModalOpen]);

  const onFinish = async (values) => {
    const formatedData = {
      name: values?.assetName,
      type: values?.type,
      host_name: values?.hostName,
      location: values?.location,
      contact: value,
    };
    setIsAddAssetLoading(true);
    try {
      const res = await newAsset.post("/asset", formatedData);
      if (res) {
        form.resetFields();
        let updateData = [formatedData, ...data];
        setData(updateData);
        setIsAddAssetModalOpen(false);
        setIsAddAssetLoading(false);
        notificationAPI.success({
          message: "Asset added successfully",
          placement: "top",
          duration: 4,
        });
      }
    } catch (err) {
       if (err?.response?.data?.token_expire === true) {
           setIsAddAssetLoading(false);
           setIsTokenExpiredModalOpen(true);
       } else {
           setIsAddAssetLoading(false);
           notificationAPI.error({
           message: "Error",
           description: err?.message,
           placement: "top",
           duration: 4,
         });
       }
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );

  const validatePhoneNumber = (_, value) => {
    if (value === undefined)
      return Promise.reject(
        new Error("Please enter a valid phone number with country code")
      );
    const numericPhone = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (numericPhone.length >= 11 && numericPhone.length <= 14) {
      return Promise.resolve(); // Pass validation for European numbers
    }
    return Promise.reject(
      new Error("Please enter a valid phone number with country code")
    );
  };
  
  return (
    <>
      <Modal
        title={
          <div className="w-full items-start  flex justify-between">
            <Title level={4}>Add Asset</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isAddAssetModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={470}
        bodyStyle={{
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "80vh",
          scrollbarWidth: "thin",
          scrollbarColor: isScrolling
            ? "#DCDCDC transparent"
            : "transparent transparent",
          "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
            opacity: isScrolling ? 1 : 0, // Show scrollbar only when scrolling
            transition: "opacity 0.3s ease",
          },
          "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#DCDCDC",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#DCDCDC",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-button": {
            display: "none",
          },
        }}
      >
        <style>
          {`
            .custom-select .ant-select-selector {
              border-radius: 9999px !important; /* Fully rounded */
              border: 1px solid #d9d9d9 !important; /* Optional: set a border color */
            }
              input[type="number"] {
              -moz-appearance: textfield; /* For Firefox */
            }

             input[type="number"]::-webkit-inner-spin-button,
             input[type="number"]::-webkit-outer-spin-button {
             -webkit-appearance: none; /* For WebKit browsers */
             margin: 0;
            }
             .custom-spin .ant-spin-dot {
              color: white;
            }
              .PhoneInput{
               width:100%;
              border-radius: 9999px !important; /* Fully rounded */
              border: 1px solid #d9d9d9 !important; /* Optional: set a border color */
              height: 40px;
             }

              .PhoneInput--focus{
              border: 1px solid #1890ff !important;
              }
               .PhoneInput .PhoneInputCountry {
                display:none;
                align-item:center;
                justify-content:center;
                width: 15%; 
                border: none;
                outline: none;
                }


               .PhoneInput .PhoneInputInput{
               max-width:90% !important;
               outline: none;
               height: 35px;
               margin-left: 12px
               }
          `}
        </style>
        <Divider className="m-0" />
        <div style={{ maxheight: "88vh" }}>
          <Form
            className="mt-5"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <Item
              label="Asset Name"
              className="font-roboto font-bold"
              name="assetName"
              rules={[
                { required: true, message: "Please enter asset name" },
                { min: 3, message: "Asset name must be at least 3 characters" },
                {
                  max: 50,
                  message: "Asset name cannot be longer than 50 characters",
                },
              ]}
            >
              <Input
                placeholder="Asset Name"
                classNames="font-normal"
                size="large"
                className="rounded-3xl font-normal"
              />
            </Item>

            <Item
              label="Type"
              className="font-roboto font-bold"
              name="type"
              rules={[{ required: true, message: "Please select a Type" }]}
            >
              <Select
                placeholder="Select a type"
                size="large"
                className="custom-select"
              >
                <Option value="Factory">Factory</Option>
                <Option value="Laboratory">Laboratory</Option>
                <Option value="Routers">Routers</Option>
              </Select>
            </Item>
            <Item
              label="Host Name"
              className="font-roboto font-bold"
              name="hostName"
              rules={[
                { required: true, message: "Please enter host name" },
                { min: 3, message: "Host name must be at least 3 characters" },
                {
                  max: 50,
                  message: "Host name cannot be longer than 50 characters",
                },
              ]}
            >
              <Input
                placeholder="Name"
                classNames="font-normal"
                size="large"
                className="rounded-3xl font-normal"
              />
            </Item>
            <Item
              label="Location"
              className="font-roboto font-bold"
              name="location"
              rules={[
                { required: true, message: "Please add location" },
                {
                  validator: (_, value) => {
                    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/; // Pattern for special characters
                    if (value && specialCharPattern.test(value)) {
                      return Promise.reject(
                        new Error("Location cannot contain special characters")
                      );
                    }
                    return Promise.resolve();
                  },
                },
                { min: 3, message: "Location must be at least 3 characters" },
                {
                  max: 100,
                  message: "Location cannot be longer than 100 characters",
                },
              ]}
            >
              <Input
                placeholder="Select Location"
                size="large"
                classNames="font-normal"
                suffix={<AimOutlined style={{ color: "grey" }} />}
                className="rounded-3xl font-normal"
              />
            </Item>
            <Item
              label="Contact"
              className="font-roboto font-bold"
              name="contact"
              rules={[
                { required: true, message: "" },
                { validator: validatePhoneNumber },
              ]}
            >
              <PhoneInput
                placeholder="[Country Code] [Phone Number] (+31 12 345 6789)"
                // defaultCountry="GB"
                international
                withCountryCallingCode
                countryCallingCodeEditable={true}
                value={value}
                onChange={(e) => setValue(e)}
                className="rounded-lg font-normal PhoneInput"
              />

            </Item>
            <Item>
              <CustomButton
                text={
                  isAddAssetLoading ? (
                    <Spin className="custom-spin" />
                  ) : (
                    "Add Asset"
                  )
                }
                type="primary"
                htmlType="submit"
                className="h-11 mt-4"
              />
            </Item>
          </Form>
        </div>
        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </Modal>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </>
  );
};
export default AddAssetModal;
