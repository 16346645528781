import React, { useState } from "react";
import axios from "axios";
import { Button } from "antd";

const TestNozomi = () => {
  const [data, setData] = useState([]);
  const [totalAssets, setTotalAssests] = useState(0);

  const callNozomi = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_ASSET_URL}/asset/getNozomiCall`);
      console.log(res.data); // Log response data
      setData(res.data.data.result); // Assuming `res.data` is an array of objects
      setTotalAssests(res.data.data.total)
    } catch (err) {
      console.error(err);
    }
  };

  const renderData = (item, index) => (
    <div key={index} style={{ marginBottom: "20px", padding: "10px", border: "1px solid #ccc" }}>
      {Object.entries(item).map(([key, value]) => (
        <div key={key} style={{ marginBottom: "8px" }}>
          <strong>{key.replace(/_/g, " ")}:</strong> {Array.isArray(value) ? value.join(", ") : value || "N/A"}
        </div>
      ))}
    </div>
  );

  return (
    <div className="py-10 px-10 mx-0 min-w-full flex flex-col items-center">
      <Button className="mt-5" onClick={callNozomi}>
        Call Nozomi Asset API
      </Button>
      <h1>Total Assets: <span>{totalAssets}</span></h1>
      <div style={{ marginTop: "20px", width: "100%" }}>
      {data.length > 0 ? (
          data.map((item, index) => (
            <pre key={index}>{JSON.stringify(item, null, 2)}</pre>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default TestNozomi;
