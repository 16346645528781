import React, { useContext, useState } from "react";
import {
  Divider,
  Form,
  Input,
  Modal,
  Typography,
  Select,
  DatePicker,
  Spin,
} from "antd";
import CustomButton from "../CustomButton";
import { notificationsContext } from "../../context/Notifications";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";


const { Title, Text } = Typography;
const { Option } = Select;

const AssignModal = ({
    isAssignModalOpen,
    setIsAssignModalOpen,
    setRefreshTable,
}) => {
  const [form] = Form.useForm();
  const { Item } = Form;

  const { api: notificationAPI } = useContext(notificationsContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setIsAssignModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      
      const assignDate= new Date(values.assign_date)
      const dueDate = new Date(values.due_date);
      const formData = {
        ...values,
        assign_date: assignDate.toISOString(),
        due_date: dueDate.toISOString(),
      };
      form.resetFields();
      setIsLoading(false);
      setIsAssignModalOpen(false);
      setRefreshTable((prev) => !prev);
    } catch (err) {
      setIsLoading(false);
      notificationAPI.error({
        message: "Error",
        description: err.message,
        placement: "top",
        duration: 4,
      });
    }
  };

  // Disable past dates in DatePicker
  const disabledDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of the current day
    return current && current.valueOf() < today.getTime(); // Disable dates before today
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
  return (
    <>
      <Modal
        title={
          <div className="w-full items-center flex justify-between">
            <Title level={4}>Assign</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isAssignModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={450}
      >
        <Divider className="m-0" />
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={customizeRequiredMark}
        >
          <Item
            label="Assigned To"
            className="font-roboto mt-3"
            name="assigned_to"
            rules={[{ required: true, message: "Please select a person" }]}
          >
            <Select placeholder="Select a person" size="large">
              <Option value="person1">Person 1</Option>
              <Option value="person2">Person 2</Option>
              <Option value="person3">Person 3</Option>
            </Select>
          </Item>

          <Item
            label="Assign Date (MM/DD/YYYY)"
            className="font-roboto"
            name="assign_date"
            rules={[{ required: true, message: "Please enter a valid date" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              format="MM/DD/YYYY"
              className="font-normal"
            />
          </Item>
          <Item
            label="Due Date (MM/DD/YYYY)"
            className="font-roboto"
            name="due_date"
            rules={[{ required: true, message: "Please enter a valid date" }]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabledDate={disabledDate}
              format="MM/DD/YYYY"
              className="font-normal"
            />
          </Item>

          <Item>
            <CustomButton
              text={isLoading ? <Spin /> : "Schedule"}
              type="primary"
              htmlType="submit"
              className="h-11 mt-4"
              disabled={isLoading}
            />
          </Item>
        </Form>

        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default AssignModal;
