import { Button } from "antd";
import React from "react";

const CustomButton = ({
  type = "",
  variant = "",
  onClick = () => {},
  htmlType = "",
  text,
  className,
  disabled,
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      onClick={() => onClick()}
      htmlType={htmlType}
      disabled={disabled}
      className={`w-full font-semibold rounded-3xl ${
        variant !== "" ? "text-appBlue border border-appBlue" : "bg-appBlue"
      } ${className ? className : "h-12"}`}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
