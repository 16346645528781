// BLUE ICONS
import dashboardBlue from "../assets/sidebarIcons/icon-dashboard-blue.png";
import assetsBlue from "../assets/sidebarIcons/icon-assets-blue.png";
import assessmentBlue from "../assets/sidebarIcons/icon-assessment-blue.png";
import remediationBlue from "../assets/sidebarIcons/icon-remediation-blue.png";
import reportingBlue from "../assets/sidebarIcons/icon-reporting-blue.png";
import integrationBlue from "../assets/sidebarIcons/icon-integration-blue.png";
import settingsBlue from "../assets/sidebarIcons/icon-settings-blue.png";

// WHITE ICONS
import dashboardWhite from "../assets/sidebarIcons/icon-dashboard-white.png";
import assetsWhite from "../assets/sidebarIcons/icon-assets-white.png";
import assessmentWhite from "../assets/sidebarIcons/icon-assessment-white.png";
import remediationWhite from "../assets/sidebarIcons/icon-remediation-white.png";
import reportingWhite from "../assets/sidebarIcons/icon-reporting-white.png";
import integrationWhite from "../assets/sidebarIcons/icon-integration-white.png";
import settingsWhite from "../assets/sidebarIcons/icon-settings-white.png";

export const sidebarTabs = [
  {
    name: "Dashboard",
    link: "/dashboard/",
    iconBlue: dashboardBlue,
    iconWhite: dashboardWhite,
  },
  {
    name: "Assets",
    link: "/dashboard/assets",
    iconBlue: assetsBlue,
    iconWhite: assetsWhite,
  },
  {
    name: "Assessment",
    link: "/dashboard/assessment",
    iconBlue: assessmentBlue,
    iconWhite: assessmentWhite,
  },
  {
    name: "Remediation",
    link: "/dashboard/remediation",
    iconBlue: remediationBlue,
    iconWhite: remediationWhite,
  },
  {
    name: "Reporting",
    link: "/dashboard/reporting",
    iconBlue: reportingBlue,
    iconWhite: reportingWhite,
  },
  {
    name: "Integration",
    link: "/dashboard/integration",
    iconBlue: integrationBlue,
    iconWhite: integrationWhite,
  },
  {
    name: "Settings",
    link: "/dashboard/settings",
    iconBlue: settingsBlue,
    iconWhite: settingsWhite,
  },
];
