import React, { useState, useContext, useEffect } from "react";
import { Divider, Form, Input, Modal, Typography, Select, Spin } from "antd";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import CustomButton from "../components/CustomButton";
import "react-phone-number-input/style.css";
import TokenExpireModal from "../components/TokenExpireModal";
import { notificationsContext } from "../context/Notifications";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import api from "../api/axios";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const { Title } = Typography;
const InviteUserModal = ({
  setIsInviteUserModalOpen,
  isInviteUserModalOpen,
  data,
  setData
}) => {

  const [isInviteUserLoading, setIsInviteUserLoading] = useState(false);
  const { api: notificationAPI } = useContext(notificationsContext);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [form] = Form.useForm();
  const { Item } = Form;

   const validatePhoneNumber = (_, value) => {
     if (value === undefined)
       return Promise.reject(
         new Error("Please enter a valid phone number with country code")
       );
      if (!isPossiblePhoneNumber(value)) {
        return Promise.reject(new Error("The phone number is not possible"));
      }

      if (!isValidPhoneNumber(value)) {
        return Promise.reject(new Error("The phone number is not valid"));
      }
     const numericPhone = value.replace(/\D/g, ""); // Remove non-numeric characters
     if (numericPhone.length >= 11 && numericPhone.length <= 14) {
       return Promise.resolve(); // Pass validation for European numbers
     }
     return Promise.reject(
       new Error("Please enter a valid phone number with country code")
     );
   };

  const handleCancel = () => {
    form.resetFields();
    setIsInviteUserModalOpen(false);
  };


  const onFinish = async (values) => {
    const formatedData = {
      name: values?.name,
      email: values?.email,
      phone_number: values?.contact
      };
    setIsInviteUserLoading(true);
    try {
      const res = await api.post("/invite-user", formatedData);
      if (res) {
        form.resetFields();
        let updateData = [formatedData, ...data];
        setData(updateData);
        setIsInviteUserModalOpen(false);
        setIsInviteUserLoading(false);
          notificationAPI.success({
            message: "User is invited successfully",
            placement: "top",
            duration: 4,
          });
      }
    } catch (err) {
      if (err?.response?.data?.token_expire === true) {
        setIsInviteUserLoading(false);
        setIsTokenExpiredModalOpen(true);
      } else {
        setIsInviteUserLoading(false);
        notificationAPI.error({
          message: "Error",
          description: err?.response?.data?.error,
          placement: "top",
          duration: 4,
        });
      }
    }
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );

  

  return (
    <>
      <Modal
        title={
          <div className="w-full items-start  flex justify-between">
            <Title level={4}>Invite User</Title>
            <CloseCircleOutlined
              onClick={handleCancel}
              className="text-[22px] cursor-pointer"
            />
          </div>
        }
        open={isInviteUserModalOpen}
        closeIcon={false}
        centered
        footer={null}
        className="!rounded-full"
        width={470}
      >
        <Divider className="m-0" />
        <div style={{ maxheight: "88vh" }}>
          <Form
            className="mt-5"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={customizeRequiredMark}
          >
            <style>
              {`
              .PhoneInput{
               width:100%;
              border: 1px solid #d9d9d9 !important; /* Optional: set a border color */
              height: 40px;
             }
              .PhoneInput--focus{
              border: 1px solid #1890ff !important;
              }
               .PhoneInput .PhoneInputCountry {
                display:none;
                align-item:center;
                justify-content:center;
                width: 15%; 
                border: none;
                outline: none;
                }
               .PhoneInput .PhoneInputInput{
               max-width:90% !important;
               outline: none;
               height: 35px;
               margin-left: 12px
               } 
                  .custom-spin .ant-spin-dot {
              color: white;
            }
          `}
            </style>
            <Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter user name" }]}
            >
              <Input
                placeholder="User Name"
                classNames="font-normal"
                size="large"
              />
            </Item>
            <Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error("The email is not valid")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input name="email" placeholder="test@test.com" size="large" />
            </Item>
            <Item
              label="Contact"
              className="font-roboto "
              name="contact"
              rules={[
                { required: true, message: "" },
                { validator: validatePhoneNumber },
              ]}
            >
              <PhoneInput
                placeholder="[Country Code] [Phone Number] (+31 12 345 6789)"
                // defaultCountry="GB"
                international
                withCountryCallingCode
                countryCallingCodeEditable={true}
                value={value}
                onChange={(e) => setValue(e)}
                className="rounded-lg font-normal PhoneInput"
              />
            </Item>
            <Item>
              <CustomButton
                text={
                  isInviteUserLoading ? (
                    <Spin className="custom-spin" />
                  ) : (
                    "Invite"
                  )
                }
                type="primary"
                htmlType="submit"
                className="h-11 mt-4"
              />
            </Item>
          </Form>
        </div>
        <CustomButton
          text="Close"
          variant="outlined"
          className="h-11 mb-3"
          onClick={handleCancel}
        />
      </Modal>
      <TokenExpireModal
        isTokenExpiredModalOpen={isTokenExpiredModalOpen}
        setIsTokenExpiredModalOpen={setIsTokenExpiredModalOpen}
      />
    </>
  );
};
export default InviteUserModal;
